import { Grid } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode[];
};

const PriceListTemplate = ({ children }: Props) => {
  // Example items - replace with your actual data

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        lg={6}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        padding={2}
        pb={8}
        gap={8}
      >
        {children
          .filter((_, index) => index % 2 === 0)
          .map((item, index) => (
            <div key={index}>{item}</div>
          ))}
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        sx={{ mt: { xs: 0, lg: 16 } }}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        gap={8}
        padding={2}
      >
        {children
          .filter((_, index) => index % 2 === 1)
          .map((item, index) => (
            <div key={index}>{item}</div>
          ))}
      </Grid>
    </Grid>
  );
};

export default PriceListTemplate;
