import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

type Props = {
  subscription: any;
};
const AccountSubscription = ({ subscription }: Props) => {
  return (
    <section>
      <hr />
      <h4>
        <a
          href={`https://dashboard.stripe.com/test/subscriptions/${subscription.id}`}
        >
          {subscription.id}
        </a>
      </h4>

      <p>Status: {subscription.status}</p>

      <p>Card last4: {subscription.default_payment_method?.card?.last4}</p>

      <p>
        Current period end:{' '}
        {new Date(subscription.current_period_end * 1000).toString()}
      </p>

      <Link to={{ pathname: '/cancel' }}>Cancel</Link>
    </section>
  );
};

const AccountPanel = () => {
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { subscriptions } = await fetch('/subscriptions').then((r) =>
        r.json()
      );

      setSubscriptions(subscriptions.data);
    };
    fetchData();
  }, []);

  if (!subscriptions) {
    return <Box></Box>;
  }

  return (
    <div>
      <h1>Account</h1>

      <a href="/prices">Add a subscription</a>
      <a href="/">Restart demo</a>

      <h2>Subscriptions</h2>

      <div id="subscriptions">
        {subscriptions.map((s, index) => {
          return <AccountSubscription key={index} subscription={s} />;
        })}
      </div>
    </div>
  );
};

export default AccountPanel;
