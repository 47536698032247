import React, { useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

type Props = {
  placeholderNode: React.ReactNode;
};

const TextFieldWithPlaceholder = ({
  placeholderNode,
  ...rest
}: Props & TextFieldProps) => {
  const [focused, setFocused] = useState<boolean>(false);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <TextField
        {...rest}
        variant="outlined"
        fullWidth
        size="small"
        onBlur={() => {
          setFocused(false);
        }}
        onFocus={() => {
          setFocused(true);
        }}
      />
      {(rest.value === '' || rest.value == null) && focused === false && (
        <div
          style={{
            position: 'absolute',
            left: '10px',
            top: '10px',
            color: '#888',
            pointerEvents: 'none',
          }}
        >
          {placeholderNode}
        </div>
      )}
    </div>
  );
};

export default TextFieldWithPlaceholder;
