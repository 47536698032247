import assets from '../../assets';
import { BlogArticleModel } from '../../models/BlogArticle';

export const blogArticles: BlogArticleModel[] = [
  {
    image: assets.blog.image1_1,
    title: 'Nowe narzędzie prawnicze',
    introduction:
      "<a href='https://prawmi.pl' style='color:#915AC2;'>PrawMi</a> to nowa <strong>inteligentna wyszukiwarka</strong> prawnicza wspierana przez AI i właśnie zadebiutowała na rynku! Skupiamy się w niej na <strong>cyfryzacji usług prawnych</strong>.",
    text: [
      {
        image: assets.blog.image1_1,
      },
      {
        sectionTitle: '',
        text: 'Dzięki <strong>prostym w użyciu interfejsie </strong> pozwala ona na szybkie wprowadzenie zapytania i zwracanie najbardziej <strong>relewantnych artykułów</strong> ustaw oraz fragmentów orzeczeń. <strong>PrawMi</strong> to idealne rozwiązanie dla prawników, którzy pragną <strong>optymalizować czas pracy</strong> i <strong>automatyzować codzienne obowiązki<strong>. W prostych słowach: PrawMi jest <strong>prostym narzędziem prawniczym</strong> w kategorii <strong>LegalTech</strong>.',
      },
      {
        sectionTitle: 'PrawMi - Jak się z nim Prawi?',
        text: "<a href='https://prawmi.pl' style='color:#915AC2;'>PrawMi</a> działa na zasadzie integracji, <strong>sztucznej inteligencji bazy: artykułów, ustaw, orzeczeń i wyszukiwarki.</strong> Po wprowadzeniu zapytania do <strong> mobilnej aplikacji dla prawników</strong>, system przeszukuje <strong>bazy danych prawnych online</strong>, aby znaleźć najbardziej odpowiednie artykuły ustaw i fragmenty orzeczeń. Dzięki <strong>elektronicznemu archiwizowaniu dokumentów prawnych </strong>, wszystkie znalezione informacje są łatwo dostępne i dobrze zorganizowane. PrawMi to <strong> narzędzie do analizy danych prawnych</strong>. Chat po znalezieniu danych, zwraca komentarz do wyszukanych zapytań, co umożliwia <strong>prawnikom</strong> lepsze zrozumienie i interpretację przepisów. Dodatkowo, <strong> elektroniczne rozliczanie czasu pracy i usługi doradcze w zakresie efektywności pracy prawnej</strong> sprawiają, że prawnicy mogą skupić się na najważniejszych aspektach swojej pracy, minimalizując czas spędzony na rutynowych zadaniach. ",
      },
      {
        image: assets.blog.image1_2,
      },
      {
        sectionTitle: 'Jak PrawMi się rozPrawia z nieefektywną pracą.',
        text: 'Korzystanie z PrawMi znacząco podnosi efektywność pracy prawników. Dzięki <strong>automatyzacji pracy i elektronicznemu zarządzaniu dokumentacją prawną</strong>, można szybko znaleźć potrzebne artykuły i orzeczenia, co oszczędza czas i redukuje koszty. To <strong>elektroniczne narzędzie prawne (LegalTech)</strong>, wspiera <strong>research prawny</strong> poprzez dostęp do najnowszych przepisów. Wszystko to sprawia, że prawnicy zyskują <strong>przewagę nad konkurencją</strong>, oferując klientom szybsze i bardziej precyzyjne usługi. <strong>Rozwiązania chmurowe dla branży prawnej</strong> zapewniają bezpieczeństwo przechowywanych danych oraz ich dostępność z dowolnego miejsca.',
      },
      {
        image: assets.blog.image1_3,
      },
      {
        sectionTitle: 'Jak stać się PrawMikiem? Darmowy okres próbny.',
        text: 'Rozumiemy, że inwestycje niosą za sobą pewne ryzyko, dlatego oferujemy nowym użytkownikom możliwość skorzystania z <strong>darmowego, miesięcznego okresu próbnego</strong>. W tym czasie prawnicy mogą przetestować wszystkie funkcje aplikacji bez żadnych kosztów, oceniając jej przydatność w codziennej pracy. Po zakończeniu okresu próbnego, PrawMi działa na zasadzie <strong>miesięcznej subskrypcji</strong>, co daje użytkownikom elastyczność w zarządzaniu usługą i możliwość łatwego wycofania się w razie potrzeby. Dzięki temu PrawMi gwarantuje <strong>pełne wsparcie techniczne i rozwojowe</strong>, dostosowując się do indywidualnych potrzeb każdego klienta.',
      },
      {
        centeredText:
          "<a href='https://prawmi.pl' style='color:#915AC2;'>Przetestuj już teraz!</a>",
      },
    ],
    date: new Date(2024, 5, 25),
    url: 'nowe-narzedzie-prawnicze',
  },
  {
    image: assets.blog.image2_1,
    title: 'Nowe funkcje w PrawMi – co się zmieniło?',
    introduction:
      'Z radością informujemy o wdrożeniu najnowszej aktualizacji PrawMi!',
    text: [
      {
        image: assets.blog.image2_1,
      },
      {
        sectionTitle: '',
        text: 'Po długotrwałym testowaniu aplikacji przez wielu prawników, wprowadziliśmy kilka funkcji oraz zadbaliśmy o to, by doświadczenie było jeszcze bardziej intuicyjne i komfortowe. Sprawdźcie poniżej, co nowego znajdziecie w aplikacji:',
      },
      {
        sectionTitle: '',
        text: '<strong>1. Aktualizacja całego systemu i poprawa wydajności</strong>',
      },
      {
        sectionTitle: '',
        text: 'W tej wersji skupiliśmy się na usprawnieniu działania PrawMi. Wprowadziliśmy zmiany w całej architekturze systemu, dzięki którym aplikacja działa szybciej i płynniej. Wydajność to kluczowa kwestia, szczególnie dla osób, które pracują nad złożonymi sprawami i przetwarzają dużą liczbę dokumentów. Teraz <strong>research prawny</strong> trwa często ok 5 sekund, czyli ponad 2 razy krócej niż wcześniej.',
      },
      {
        sectionTitle: '',
        text: '<strong>2. Wyświetlanie źródeł poprzednich wiadomości</strong>',
      },
      {
        sectionTitle: '',
        text: 'Dzięki tej nowej funkcji możecie łatwo wrócić do źródeł i dokumentów, które już były omawiane w danej konwersacji. Po kliknięciu w którąś ze swoich poprzednich wiadomości zobaczycie <strong>listę polecanych artykułów lub orzeczeń</strong>, związanych właśnie z nią. Pozwoli to na szybkie odświeżenie kontekstu bez potrzeby szukania ich ponownie – przydatne szczególnie przy bardziej złożonych sprawach.',
      },
      {
        image: assets.blog.image2_2,
      },
      {
        sectionTitle: '',
        text: '<strong>3. Orzeczenia sądów administracyjnych</strong>',
      },
      {
        sectionTitle: '',
        text: 'Rozszerzyliśmy naszą bazę danych o dziesiątki tysięcy <strong>nowych orzeczeń sądów administracyjnych</strong>. Dzięki temu możecie teraz <strong>wyszukiwać i analizować dokumenty</strong> dotyczące szerokiego zakresu ustaw i przepisów, co znacząco ułatwi pracę <strong>nad sprawami administracyjnymi</strong> i pozwoli być na bieżąco ze zmianami w przepisach.',
      },
      {
        sectionTitle: '',
        text: '<strong>4. Ulepszona wersja mobilna</strong>',
      },
      {
        sectionTitle: '',
        text: 'Użytkownicy komórek i tabletów mogą teraz cieszyć się pełnym zakresem <strong>funkcji PrawMi</strong>. Tryb mobilny został zoptymalizowany, aby zapewnić wyższy komfort przeglądania dokumentów i użytkowania tego <strong>narzędzia prawniczego</strong> na urządzeniach mobilnych. Bez względu na to, gdzie jesteście, macie dostęp do wszystkich funkcji znanych z wersji przeglądarkowej, bez potrzeby instalowania dodatkowego oprogramowania. Wystarczy wejść na prawmi.pl w dowolnej przeglądarce na telefonie.',
      },
      {
        image: assets.blog.image2_3,
      },
      {
        sectionTitle: '',
        text: '<strong>5. Tryb ciemny (dark mode)</strong>',
      },
      {
        sectionTitle: '',
        text: 'W odpowiedzi na potrzeby użytkowników wprowadziliśmy długo wyczekiwany tryb ciemny! Teraz zarówno na komputerze, jak i na urządzeniu mobilnym możecie przełączyć kolorystykę PrawMi na ciemną. Ten przyjazny dla oczu tryb, z ciemnym tłem pozwala na dłuższą i bardziej komfortową pracę bez zmęczenia wzroku – szczególnie przydatne dla nocnych marków 😊. Aplikacja automatycznie dostosowuje się do motywu wybranego w systemie (telefon, Mac, Windows) i wybiera adekwatny kolor.',
      },
      {
        sectionTitle: '',
        text: 'Zapraszamy do wypróbowania nowych funkcji i podzielenia się swoimi opiniami! Pamiętajcie, że Wasze sugestie pomagają nam rozwijać PrawMi zgodnie z Waszymi oczekiwaniami i potrzebami. A podchodzimy do każdej prośby poważnie i indywidualnie 🙂',
      },
    ],
    date: new Date(2024, 10, 4),
    url: 'nowe-funkcje-prawmi',
  },
  {
    image: assets.blog.image3_1,
    title: 'Jak osiodłać prawem wierzchowca czołowych modeli AI?',
    introduction: '',
    text: [
      {
        image: assets.blog.image3_1,
        imageDescription: 'Grafika wygenerowana przez GROK AI',
      },
      {
        sectionTitle: '',
        text: 'Obserwując globalny postęp technologiczny można wyobrazić sobie rozwój AI jako pędzącego wierzchowca, który z miesiąca na miesiąc pokonuje to coraz większe odległości między tym od czego zaczynał, a tym czym się staje. Jak go ujarzmić? Jednym z pomysłów może być legislacja użytkowania i rozwoju AI.',
      },
      {
        sectionTitle:
          'Regulacja prawem - definicje ustawodawcze i administracyjne.',
        text: 'Tworzenie definicji ustawodawczych i administracyjnych w Polsce odbywa się zgodnie z określonymi procedurami, gdzie ustawodawcze definicje uchwala Sejm i Senat, a administracyjne – ministerstwa i organy administracji publicznej. Czy taki model tworzenia prawa poradzi sobie z legislacją AI? Zagadnienia związane z AI są obecnie intensywnie regulowane w USA i UE, a zmiany prawne, takie jak AI Act w UE, stanowią kluczowy przykład. Frontier AI ilustruje, jak te przepisy wpływają na rozwój technologii.',
      },
      {
        sectionTitle: 'Frontier AI – inspiracja z USA',
        text: 'Warto zwrócić uwagę na przykład Stanów Zjednoczonych, gdzie rozwój sztucznej inteligencji, w tym Frontier AI, jest wspierany przez odpowiednie regulacje. Frontier AI odnosi się do zaawansowanych systemów AI, które mogą mieć istotny wpływ na społeczeństwo i gospodarkę. W USA Kongres oraz agencje federalne opracowują definicje i regulacje w tym zakresie, by zapewnić bezpieczny rozwój technologii. Polska mogłaby pójść tym śladem, tworząc jasne i precyzyjne definicje AI, które umożliwią skuteczniejsze regulowanie innowacyjnych technologii i ich wdrażanie w różnych sektorach gospodarki.',
      },
      {
        sectionTitle: 'Wyzwania związane z tworzeniem prawa AI',
        text: 'Prawne: <ul style="padding-bottom: 20px; list-style-type: disc; padding-left: 20px;"><li><strong>Kontrola konstytucyjności: </strong>Trybunał Konstytucyjny może uznać, że nowa definicja narusza ustawę zasadniczą.</li><li><strong>Zakres delegacji: </strong> Sądy mogą zakwestionować, czy organ administracyjny miał odpowiednie uprawnienia do zmiany definicji</li></ul> Biznesowe i społeczne:<ul style="list-style-type: disc; padding-left: 20px;"><li><strong>Stworzenie nieprecyzyjnych definicji: </strong>Mogą prowadzić do niejasności prawnych i utrudniać wdrażanie nowych technologii.<li><strong>Ryzyko spowolnienia innowacji: </strong>Zbyt sztywne regulacje mogą hamować rozwój AI.</li><li><strong>Brak jednolitych standardów: </strong>Konieczność uwzględnienia międzynarodowych norm i szybkie ich dostosowywanie.<li><strong>Równowaga między bezpieczeństwem a innowacyjnością: </strong> Regulacje muszą chronić interes publiczny, nie ograniczając jednocześnie potencjału nowych technologii.</li><li><strong>Współpraca międzysektorowa: </strong>Potrzeba zaangażowania ekspertów z różnych dziedzin, aby zapewnić efektywność regulacji.</li></ul>',
      },

      {
        sectionTitle: 'Podsumowanie',
        text: 'Definicje są kluczowe dla skutecznych regulacji. W Polsce ich aktualizacja wymaga współpracy między parlamentem a organami administracji. Precyzyjne i elastyczne definicje pozwalają na szybkie dostosowanie przepisów do zmieniających się realiów. Wprowadzenie definicji i regulacji dla zaawansowanych technologii, takich jak Frontier AI, mogłoby umożliwić Polsce konkurowanie na globalnej arenie technologicznej.',
      },
      {
        sectionTitle: '',
        text: '',
      },
    ],
    date: new Date(2025, 0, 17),
    url: 'okielznanie-ai',
    sources: [
      'Defining “frontier AI”',
      'Key concepts in U.S. federal law',
      'Suzanne Van Arsdale, Kevin Frazier',
      '<a href="https://law-ai.org/frontier-ai-definitions/">https://law-ai.org/frontier-ai-definitions/</a>',
    ],
  },
];
