import * as React from 'react';
import styles from './styles.module.scss';
import image1 from '../../../../../assets/french/appimg.webp';
import imageMain from '../../../../../assets/french/laloireMain.webp';
import { Box } from '@mui/material';

const DescriptionSchemaPartOne = () => {
  const renderImageBox = (
    image: string,
    borderType: 'all' | 'top' | 'bottom',
    style: React.CSSProperties = {}
  ) => (
    <Box
      style={{
        background: 'white !important',
        border: '1px solid lightgray',
        borderRadius: '10px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
        ...style,
      }}
    >
      <Box
        component="img"
        width="100%"
        src={image}
        style={{
          borderRadius: borderType === 'all' ? '9px' : 0,
          borderTopLeftRadius:
            borderType === 'top' || borderType === 'all' ? '9px' : 0,
          borderTopRightRadius:
            borderType === 'top' || borderType === 'all' ? '9px' : 0,
          borderBottomLeftRadius:
            borderType === 'bottom' || borderType === 'all' ? '9px' : 0,
          borderBottomRightRadius:
            borderType === 'bottom' || borderType === 'all' ? '9px' : 0,
          overflow: 'hidden',
        }}
      />
    </Box>
  );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '160px',
        background: '#d9d9d9',
      }}
    >
      <div style={{ width: '50%' }}>
        <div style={{ width: '50%' }}>
          {renderImageBox(imageMain, 'all', {
            maxWidth: '550px',
            marginBottom: '10px',
          })}
        </div>
      </div>
      <div style={{ width: '50%', justifyContent: 'end', display: 'flex' }}>
        <div>
          <p className={styles.title}>Décrire le cas en détail</p>
          {renderImageBox(image1, 'all', {
            maxWidth: '550px',
            marginBottom: '10px',
          })}
        </div>
      </div>
    </div>
  );
};

export default DescriptionSchemaPartOne;
