import { Box, Grid, Typography } from '@mui/material';
import assets from '../../../../../assets';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const renderTestimony = (
  name: string,
  text: string,
  image: any,
  isMd: boolean
) => {
  return (
    <Box
      display={'flex'}
      flexWrap={'wrap'}
      justifyContent={isMd ? 'center' : 'flex-start'}
    >
      <Box
        id="testimony-image"
        width={'240px'}
        height={'320px'}
        sx={{
          borderRadius: '10px',
          overflow: 'hidden',
          objectFit: 'cover',
          backgroundColor: 'white',
        }}
        component={'img'}
        src={image}
        alt="Testymoniał 1"
      />

      <Box
        flex={1}
        maxWidth={'350px'}
        minWidth={'130px'}
        marginLeft={isMd ? '0px' : '20px'}
        marginTop={isMd ? '20px' : '0px'}
        id="testimony-text"
      >
        <Box display={'flex'} flexDirection={'column'} height={'100%'}>
          <Box flex={1}>
            <Typography
              sx={{ whiteSpace: 'pre-wrap' }}
              fontSize={'18px'}
              fontStyle={'italic'}
            >
              {'"' + text + '"'}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ whiteSpace: 'pre-wrap' }}
              fontSize={'18px'}
              fontWeight={'bold'}
            >
              {name}
            </Typography>
            <Typography
              sx={{ whiteSpace: 'pre-wrap' }}
              fontSize={'18px'}
              fontWeight={'light'}
            >
              {'Prawniczka'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const Testimonies = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  const textOne = t('start:testimonies.testimonieOne');
  const textTwo = t('start:testimonies.testimonieTwo');

  return (
    <Box px={isMd ? 4 : 21}>
      <Grid container spacing={2} marginTop={'70px'} marginBottom={'70px'}>
        <Grid item xs={12} sm={12} md={6}>
          {renderTestimony(
            'Wiktoria Forystek',
            textOne,
            assets.images.Wiktoria,
            isMd
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          {renderTestimony(
            'Natasza Gadowska',
            textTwo,
            assets.images.Natasza,
            isMd
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
