import UserPreferences from '../../components/organisms/user-preferences/UserPreferences';
import style from './settingsPage.module.scss';

const SettingsPage = () => {
  return (
    <div className={style.mainDiv}>
      <UserPreferences />
    </div>
  );
};

export default SettingsPage;
