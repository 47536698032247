import React from 'react';
import colors from '../../../../../configs/colorConfig';
import { Box, Link, LinkProps, styled } from '@mui/material';
import assets from '../../../../../assets';
import { useTranslation } from 'react-i18next';

const StyledLink = styled(Link)<LinkProps>({
  background: colors.primary,
  color: 'white',
  textDecoration: 'none',
  '&&:hover': {
    background: colors.lightGray,
  },
  padding: '10px',
  borderRadius: '15px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '200px',
});

const Hero = () => {
  const { t } = useTranslation();

  return (
    <>
      <div id="o-nas">
        <div
          className="m-auto overflow-hidden mx-4 px-2 md:px-12 h-5/6"
          data-aos="zoom-in"
        >
          <div
            id="hero"
            className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left"
          >
            <div
              className="lg:w-3/5 flex flex-col justify-center"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <h1
                style={{ color: colors.primary }}
                className="mb-5 md:text-5xl text-3xl font-bold"
              >
                {t('start:hero.title1')}
                <br />
                {t('start:hero.title2')}
              </h1>
              <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">
                {t('start:hero.text')}
              </div>
              <div className="mb-4 space-x-0 md:space-x-2 md:mb-8 flex justify-center lg:justify-start">
                <StyledLink href="#kontakt">
                  {t('start:hero.button')}
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </StyledLink>
              </div>
            </div>
            <div
              className="flex justify-center lg:justify-end w-full lg:w-2/5"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              <Box
                component={'img'}
                src={assets.icons.homephoto}
                width={'500px'}
                my="80px"
                borderRadius="2%"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
