import React, { useCallback, useState, createContext } from 'react';
import { LawModel } from '../models/LawModel';

interface PropsBasicDataProvider {
  children: React.ReactNode;
}

interface ContextDataModel {
  text: string;
  results: LawModel[];
  setText: (text: string) => void;
  setResults: (results: LawModel[]) => void;
}

export const SearchBarContext: React.Context<ContextDataModel> = createContext({
  text: '' as string,
  results: [] as LawModel[],
  setText: (text: string) => {},
  setResults: (results: LawModel[]) => {},
});

export const SearchBarProvider: any = ({
  children,
}: PropsBasicDataProvider) => {
  const [localText, setLocalText] = useState<string>('');
  const [localResults, setLocalResults] = useState<LawModel[]>([]);

  const contextValue = {
    text: localText,
    results: localResults,
    setText: useCallback((text: string) => setLocalText(text), []),
    setResults: useCallback(
      (results: LawModel[]) => setLocalResults(results),
      []
    ),
  };

  return (
    <SearchBarContext.Provider value={contextValue}>
      {children}
    </SearchBarContext.Provider>
  );
};
