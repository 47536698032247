import styles from './styles.module.scss';

import { ReactNode } from 'react';
import { TileModel } from './TileFeaturesModel';

interface FeatureItemProps {
  title: string;
  icon: ReactNode;
  colored?: boolean;
}

const FeatureItem: React.FC<FeatureItemProps> = ({ title, icon, colored }) => {
  return (
    <div className={styles.featureItem}>
      <div className={styles.iconPlaceholder}>{icon}</div>
      <div className={styles.featureContent}>
        <p style={{ color: !colored ? '#38b6ff' : 'white' }}>{title}</p>
      </div>
    </div>
  );
};

type Props = {
  tileData: TileModel;
  colored?: boolean;
};

const PriceTile = ({ tileData, colored }: Props) => {
  return (
    <div
      className={styles.priceList}
      style={{ backgroundColor: colored ? '#38b6ff' : 'transparent' }}
    >
      <h4
        className={styles.tileTitle}
        style={{ color: !colored ? '#38b6ff' : 'white' }}
      >
        {tileData.title}
      </h4>
      {tileData.features.map((feature, index) => (
        <FeatureItem
          key={index}
          title={feature.title}
          icon={feature.icon}
          colored={colored}
        />
      ))}
    </div>
  );
};

export default PriceTile;
