import { CircularProgress } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

type Props = {
  text: string;
  done: boolean;
  loading: boolean;
};

const LoadingRow = ({ text, done, loading }: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: '10px',
        height: '40px',
        alignItems: 'center',
      }}
    >
      <div style={{ width: '20px', display: 'flex', alignItems: 'center' }}>
        {loading && !done && <CircularProgress size={20} />}
        {done && <CheckIcon style={{ color: 'green' }} />}
      </div>
      <p
        style={{
          color:
            done || loading ? 'var(--text-primary)' : 'var(--text-disabled)',
        }}
      >
        {text}
      </p>
    </div>
  );
};

export default LoadingRow;
