import React from 'react';
import colors from '../../../../../configs/colorConfig';
import { Button, Link, LinkProps, styled } from '@mui/material';
import assets from '../../../../../assets';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
const StyledLink = styled(Link)<LinkProps>({
  background: colors.primary,
  color: 'white',
  textDecoration: 'none',
  '&&:hover': {
    background: colors.lightGray,
  },
  padding: '10px',
  borderRadius: '15px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '200px',
});

const Hero = () => {
  const { t } = useTranslation();

  return (
    <>
      <div
        id="o-nas"
        style={{
          backgroundImage: `url(${assets.french.home})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          padding: '150px',
        }}
      >
        <div style={{ width: '80%' }}>
          <h1 className={styles.title}>{t('start:hero.title1')}</h1>
          <div className={styles.text}>{t('start:hero.text')}</div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'right',
            }}
          >
            <button
              color="primary"
              style={{
                margin: '50px',
                background: '#38b6ff',
                padding: '10px 20px',
                borderRadius: '5px',
              }}
            >
              <p
                style={{
                  color: '#005b2f',
                  fontFamily: 'Merriweather',
                  fontSize: '20px',
                }}
              >
                {t('start:hero.button')}
              </p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
