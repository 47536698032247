import { firestore } from 'configs/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import ChatFeedDocument from 'module-private/components/organisms/chat-feed-document/ChatFeedDocument';
import DashboardTemplate from 'module-private/components/templates/dashboard-template/DashboardTemplate';
import { DocumentModel } from 'module-private/models/DocumentModel';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import DocumentsRightPanel from 'module-private/components/organisms/documents-right-panel/DocumentsRightPanel';
import { StatusEnum } from 'module-private/components/organisms/document-display/components/LoadingDocumentPanel';

const DocumentsPage = () => {
  const { id } = useParams();
  const [chosenDocument, setChosenDocument] = useState<DocumentModel | null>(
    null
  );
  const [chosenContent, setChosenContent] = useState<string>('');
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const [status, setStatus] = useState<StatusEnum | null>(null);
  const fetchDocument = async () => {
    if (id) {
      const document = await getDoc(doc(firestore, 'Documents', id));

      const newDoc: DocumentModel = {
        id: id,
        lastModified: new Date(),
        messages: document.data()?.messages,
        content: document.data()?.document,
      };
      setChosenDocument(newDoc);
      setChosenContent(newDoc.content);
    } else {
      setChosenDocument(null);
      setChosenContent('');
    }
  };

  useEffect(() => {
    fetchDocument();
    //eslint-disable-next-line
  }, [id]);

  return (
    <DashboardTemplate
      leftPanel={
        <div className={styles.leftSidePanel}>
          <ChatFeedDocument
            onNewQuestion={() => {
              setChosenContent('');
            }}
            chosenDocument={chosenDocument}
            onDocumentContentChange={(value) => {
              setLoadingDocuments(false);

              setChosenContent((prevContent) => prevContent + value);
            }}
            onConversationStarted={() => {
              setLoadingDocuments(true);
            }}
            onStatusChange={(status) => {
              setStatus(status);
            }}
          />
        </div>
      }
      rightPanel={
        <div className={styles.rightSidePanel}>
          <DocumentsRightPanel
            chosenDocument={chosenDocument}
            chosenContent={chosenContent}
            loadingDocuments={loadingDocuments}
            status={status}
          />
        </div>
      }
    />
  );
};

export default DocumentsPage;
