import Hero from './compnents/hero/Hero';
import Footer from './compnents/Footer';
import AskQuestionForm from './compnents/ask-question-form/AskQuestionForm';
import CookieConsent from 'react-cookie-consent';
import colors from '../../../configs/colorConfig';
import { Box, Divider } from '@mui/material';
import Seo from './compnents/Seo';
import { useTranslation } from 'react-i18next';
import { Testimonies } from './compnents/testimonies/Testimonies';
import DescriptionSchema from './compnents/description-schema/DescriptionSchema';
import { getCssVariable } from 'styles/getVariables';
import PriceList from './compnents/price-list/PriceList';

const StartPage = () => {
  const { t } = useTranslation();

  return (
    <Box display={'flex'} justifyContent={'center'} width={'100vw'}>
      <Box maxWidth={'2000px'}>
        <Hero />
        <DescriptionSchema />
        <PriceList />
        <Testimonies />
        <Divider sx={{ borderColor: getCssVariable('--medium-gray') }} />
        <AskQuestionForm />
        <Divider sx={{ borderColor: getCssVariable('--medium-gray') }} />
        <Seo />
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Rozumiem"
          cookieName="myAwesomeCookieName"
          style={{ background: '#2B373B' }}
          buttonStyle={{
            background: colors.primary,
            color: 'white',
            fontSize: '13px',
          }}
          expires={150}
        >
          {t('start:cookies')}
        </CookieConsent>
      </Box>
    </Box>
  );
};

export default StartPage;
