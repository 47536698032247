import { useEffect, useState } from 'react';
import LoadingRow from './LoadingRow';

type Porps = {
  status: StatusEnum | null;
};

export enum StatusEnum {
  S1_GENERATION = 's1_generation',
  S2_1_VALIDATION = 's2_1_validation',
  S2_2_VALIDATION = 's2_2_validation',
  S2_3_VALIDATION = 's2_3_validation',
  S3_AGGREGATION = 's3_aggregation',
}

const texts = {
  [StatusEnum.S1_GENERATION]: 'Generowanie dokumentu na podstawie zapytania',
  [StatusEnum.S2_1_VALIDATION]: 'Proponowanie poprawek w dokumencie 1/3',
  [StatusEnum.S2_2_VALIDATION]: 'Proponowanie poprawek w dokumencie 2/3',
  [StatusEnum.S2_3_VALIDATION]: 'Proponowanie poprawek w dokumencie 3/3',
  [StatusEnum.S3_AGGREGATION]: 'Tworzenie finalnej wersji',
};

const LoadingDocumentPanel = ({ status }: Porps) => {
  const [listOfDone, setListOfDone] = useState<StatusEnum[]>([]);

  const handleDone = (currentStatus: StatusEnum) => {
    const statusOrder = Object.values(StatusEnum);
    const currentIndex = statusOrder.indexOf(currentStatus);
    const completedStatuses = statusOrder.slice(0, currentIndex + 1);
    setListOfDone(completedStatuses);
  };

  useEffect(() => {
    if (status) {
      handleDone(status);
    }
  }, [status]);

  return (
    <div>
      {Object.entries(texts).map(([key, value]) => (
        <LoadingRow
          key={key}
          text={value}
          done={listOfDone.includes(key as StatusEnum) && key !== status}
          loading={key === status}
        />
      ))}
    </div>
  );
};

export default LoadingDocumentPanel;
