import { firestore } from 'configs/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

async function fetchDefaultFirebasePrice(): Promise<{
  price: number;
  days: number;
}> {
  try {
    const hyperparametersDoc = await getDoc(
      doc(firestore, 'Hyperparameters', 'default')
    );
    const defaultPrice = hyperparametersDoc.data()?.default_monthly_price_pln;

    const trialDays = hyperparametersDoc.data()?.default_trial_days;

    const price = defaultPrice ? defaultPrice : 350; // fallback to 350 if the value doesn't exist
    const days = trialDays ? trialDays : 14;
    return { price: price, days: days };
  } catch (error) {
    console.error('Error fetching default price:', error);
    return { price: 350, days: 14 };
  }
}

export default fetchDefaultFirebasePrice;
