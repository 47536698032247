import axios from 'api/axios';
import useTabs from 'hooks/useTabs';
import { CaseModel } from 'module-private/models/CaseModel';
import { RulingModel } from 'models/RulingModel';
import { useEffect, useState } from 'react';
import { auth } from '../../../../configs/firebaseConfig';
import { User } from 'firebase/auth';
import RulingsList from '../rulings-list/RulingsList';
import PaginationButtons from 'common/pagination-buttons/PaginationButtons';

function isEmpty(value: any) {
  return (
    value == null || (typeof value === 'string' && value.trim().length === 0)
  );
}

const RulingsListPanel = () => {
  const { displayed } = useTabs();
  const [rulingsList, setRulingsList] = useState<RulingModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [listOfArticleNames, setListOfArticleNames] = useState<string[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);

  const handleSelect = (selectedValue: string | null) => {
    if (selectedValue) {
      listOfArticleNames
        .map((option) =>
          option.replaceAll('<sup>', '-').replaceAll('</sup>', '')
        )
        .indexOf(selectedValue);
    }
  };

  const fetchData = async (currentUser: User) => {
    let url = (displayed as CaseModel).url ?? '';
    let entityPath = (displayed as CaseModel).entityPath ?? '';
    let dataToSend = {
      act_unified_url: url.replace('search-', ''),
      entity_path: entityPath,
      page: page,
      page_size: 10,
    };

    const token = await currentUser.getIdToken();
    let headers = {
      Authorization: `Bearer ${token}`,
      accept: '*',
      'content-type': 'application/json',
    };
    setLoading(true);
    await axios
      .post(`/lawmodel/rulings/by-law-entity`, dataToSend, { headers: headers })
      .then(async (res) => {
        const list: RulingModel[] = [];
        if (isEmpty(res.data) || res.data.error != null) {
          setLoading(false);
          return;
        }
        res.data.rulings.map(async (data: any) => {
          let url = data.link;
          let ord: RulingModel = {
            uniqueKey: url,
            type: 'ruling',
            title: data.court_case,
            url: url,
            startIndex: data.start_index ?? 0,
            endIndex: data.end_index ?? data.start_index + 1000,
            text: data.text_excerpt.replace('title=', ' ').split(' ').join(' '),
          };
          list.push(ord);
        });
        setRulingsList([...list]);
        setPageCount(Math.ceil(res.data.count / 10));
        // onRulingLoad(list);
      });
    setLoading(false);
  };

  useEffect(() => {
    if (auth.currentUser) {
      fetchData(auth.currentUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayed, page]);

  return (
    <div
      style={{
        marginTop: '20px',
        height: '100%',
      }}
    >
      {/* <Autocomplete
        sx={{ width: '110px' }}
        size="small"
        autoHighlight={true}
        onChange={(e, v) => {
          handleSelect(v);
        }}
        renderInput={(params) => {
          return (
            <TextField
              autoFocus={true}
              {...params}
              sx={{ '& .MuiInputBase-root': { fontSize: '13px' } }}
            />
          );
        }}
        options={listOfArticleNames.map((option) =>
          option.replaceAll('<sup>', '-').replaceAll('</sup>', '')
        )}
        //getOptionLabel={option => option.replaceAll("<sup>", "-").replaceAll("</sup>", "")}
        PaperComponent={(props) => (
          <div
            {...props}
            style={{
              backgroundColor: getCssVariable('--background-color-primary'),
            }}
          />
        )}
        renderOption={(props, option, { inputValue }) => {
          const label = option;
          if (label !== '') {
            return <Typography {...props}>{label}</Typography>;
          }
        }}
        componentsProps={{
          popper: {
            sx: {
              '& .MuiAutocomplete-option': {
                fontSize: '13px',
                margin: '0px',
              },
            },
          },
        }}
      /> */}
      <div
        style={{
          height: 'calc(100% - 80px)',
          overflowY: 'auto',
          padding: '20px',
        }}
      >
        <RulingsList rulingsList={rulingsList} loading={loading} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          marginTop: '10px',
        }}
      >
        <PaginationButtons
          page={page}
          pageCount={pageCount}
          onPageChange={(p) => {
            setPage(p);
          }}
        />
      </div>
    </div>
  );
};

export default RulingsListPanel;
