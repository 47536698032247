import { Box, Typography } from '@mui/material';
import { ConversationModel } from '../../../../models/ConversationModel';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getCssVariable } from 'styles/getVariables';
import { useTranslation } from 'react-i18next';
import { teal } from '@mui/material/colors';

function isWithinLastWeek(date: Date) {
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  return date >= oneWeekAgo;
}

function isYesterday(date: Date) {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  );
}

const isToday = (someDate: Date) => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

type Props = {
  conversation: ConversationModel;
  index: number;
  onDeleteClick: (conversation: any) => void;
  onClick: (conversation: any) => void;
};
const ConversationSidebarButton = ({
  conversation,
  index,
  onDeleteClick,
  onClick,
}: Props) => {
  const [text, setText] = useState('');
  const { t } = useTranslation();

  const dayToName = (day: number) => {
    switch (day) {
      case 0:
        return t('dashboard:time.day.sunday');
      case 1:
        return t('dashboard:time.day.monday');
      case 2:
        return t('dashboard:time.day.tuesday');
      case 3:
        return t('dashboard:time.day.wednesday');
      case 4:
        return t('dashboard:time.day.thursday');
      case 5:
        return t('dashboard:time.day.friday');
      case 6:
        return t('dashboard:time.day.saturday');
    }
  };

  const monthToRoman = (month: number) => {
    switch (month) {
      case 1:
        return 'I';
      case 2:
        return 'II';
      case 3:
        return 'III';
      case 4:
        return 'IV';
      case 5:
        return 'V';
      case 6:
        return 'VI';
      case 7:
        return 'VII';
      case 8:
        return 'VIII';
      case 9:
        return 'IX';
      case 10:
        return 'X';
      case 11:
        return 'XI';
      case 12:
        return 'XII';
    }
  };

  useEffect(() => {
    const updateText = () => {
      let te = getText(conversation);
      setText(te);
    };
    updateText();

    const intervalId = setInterval(updateText, 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation]);

  const getText = (conversation: ConversationModel): string => {
    let lastAppended = new Date(conversation.lastAppended);
    const now = new Date();
    if (isToday(lastAppended)) {
      const startShiftTime = moment(now);
      const endShiftTime = moment(lastAppended);

      const duration = moment.duration(startShiftTime.diff(endShiftTime));

      if (duration.hours() < 1 && duration.minutes() < 60) {
        return duration.minutes() + ' min';
      } else if (
        duration.hours() < 1 &&
        duration.minutes() >= 20 &&
        duration.minutes() < 40
      ) {
        return '30 min';
      } else if (duration.hours() < 1 && duration.minutes() >= 40) {
        return '45 min';
      } else {
        return duration.hours() + ' ' + t('dashboard:time.hour');
      }
    } else if (isYesterday(lastAppended)) {
      return (
        lastAppended.getHours().toString().padStart(2, '0') +
        ':' +
        lastAppended.getMinutes().toString().padStart(2, '0')
      );
    } else if (isWithinLastWeek(lastAppended)) {
      return dayToName(conversation.lastAppended.getDay()) ?? '';
    } else {
      return (
        conversation.lastAppended.getDate() +
        ' ' +
        monthToRoman(conversation.lastAppended.getMonth() + 1)
      );
    }
  };

  return (
    <>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        sx={{ '&:hover .hoverChild': { opacity: 1 } }}
      >
        <Box
          component={'button'}
          className="listItemSidebar"
          height={'35px'}
          display={'flex'}
          alignItems={'center'}
          alignContent={'center'}
          onClick={() => {
            onClick(conversation);
          }}
          sx={{
            background: getCssVariable('--navbar-color'),
            border: 'none',
            ':hover': {
              background: '#132034',
            },
            py: '4px',
          }}
          key={index}
          width={'78px'}
          justifyContent={'left'}
          marginRight={'5px'}
        >
          <Typography
            color={'lightgray'}
            className="listItemSidebar"
            width={'100%'}
            alignContent={'center'}
            sx={{
              fontSize: '16px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default ConversationSidebarButton;
