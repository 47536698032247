import {
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
  TypographyProps,
} from '@mui/material';
import ShortcutKeys from './components/ShortcutKeys';
import { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';

const StyledTypography = styled(Typography)<TypographyProps>({
  fontSize: '14px',
  color: 'dimGray',
});

type Props = {
  cmdKeyUsed: boolean;
  onClose: () => void;
};
const ShortcutPanel = ({ cmdKeyUsed, onClose }: Props) => {
  const [cmdKeyValue, setCmdKeyValue] = useState<string>('Ctrl');
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (cmdKeyUsed) {
      setCmdKeyValue('Cmd');
    } else {
      setCmdKeyValue('Ctrl');
    }
  }, [cmdKeyUsed]);

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  }, []);

  return (
    <Grid container>
      <IconButton
        size="small"
        ref={buttonRef}
        onClick={() => {
          onClose();
        }}
        sx={{ position: 'fixed', right: '0px', top: '0px' }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <Grid item xs={12}>
        <Typography fontSize={'18px'}>Skróty klawiszowe</Typography>
        <Box padding={'10px'}>
          <Divider />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <StyledTypography>Pokaż skróty</StyledTypography>
        <ShortcutKeys keys={[cmdKeyValue, '/']} />
      </Grid>
      <Grid
        item
        xs={12}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <StyledTypography>Przechodzenie do pola wpisywania</StyledTypography>
        <ShortcutKeys keys={['/']} />
      </Grid>
      <Grid
        item
        xs={12}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <StyledTypography>Nowe okno czatu</StyledTypography>
        <ShortcutKeys keys={[cmdKeyValue, 'shift', '/']} />
      </Grid>
    </Grid>
  );
};

export default ShortcutPanel;
