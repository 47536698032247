import Hero from './components/hero/Hero';
import Footer from './components/Footer';
import CookieConsent from 'react-cookie-consent';
import { Box, Divider } from '@mui/material';
import Seo from './components/Seo';
import { useTranslation } from 'react-i18next';
import DescriptionSchema from './components/description-schema/DescriptionSchema';
import DescriptionSchemaPartOne from './components/description-schema/DescriptionSchemaPartOne';
import DescriptionSchemaPartThree from './components/description-schema/DescriptionSchemaPartThree';
import { getCssVariable } from 'styles/getVariables';
import PriceList from './components/price-list/PriceList';
import colors from 'configs/colorConfig';

const StartPageFrench = () => {
  const { t } = useTranslation();

  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      width={'100vw'}
      sx={{ background: '#d9d9d9' }}
    >
      <Box maxWidth={'2000px'}>
        <Hero />
        <DescriptionSchemaPartOne />
        <DescriptionSchema />

        <PriceList />
        <Divider sx={{ borderColor: getCssVariable('--medium-gray') }} />
        <Seo />
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Rozumiem"
          cookieName="myAwesomeCookieName"
          style={{ background: '#2B373B' }}
          buttonStyle={{
            background: colors.primary,
            color: 'white',
            fontSize: '13px',
          }}
          expires={150}
        >
          {t('start:cookies')}
        </CookieConsent>
      </Box>
    </Box>
  );
};

export default StartPageFrench;
