import { CircularProgress } from '@mui/material';
import colors from 'configs/colorConfig';
import styles from './styles.module.scss';

type Props = {
  isLoading: boolean;
  size?: number;
  description?: string;
  children: React.ReactNode;
  loadingHeight?: string;
};

const LoadingWrapper = ({
  isLoading,
  children,
  size = 36,
  description,
  loadingHeight = '100%',
}: Props) => {
  return (
    <>
      {isLoading ? (
        <div
          className={styles.loadingWrapper}
          style={{ height: loadingHeight }}
        >
          <div>
            <div className={styles.circularWrapper}>
              <CircularProgress size={size} style={{ color: colors.primary }} />
            </div>
            <p className={styles.description}>{description}</p>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default LoadingWrapper;
