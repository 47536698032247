import React from 'react';
import colors from '../../../configs/colorConfig';
import AskQuestionForm from '../start-page/compnents/ask-question-form/AskQuestionForm';

export const ContactPage = () => {
  return (
    <div>
      <div
        style={{
          width: '100vw',
          display: 'flex',
          background: colors.grayBG,
          justifyContent: 'center',
        }}
      >
        <div style={{ marginTop: '100px', marginBottom: '50px' }}>
          <AskQuestionForm />
          <p>
            Można się z nami skontaktowac równiez przez adres email:
            obywateltb@gmail.com
          </p>
        </div>
      </div>
    </div>
  );
};
