import axios from 'axios';

export default axios.create({
  // headers: { 'X-SSE': 'true' },

  baseURL: process.env.REACT_APP_INTERFACE_URL,
  //   onDownloadProgress: progressEvent => {
  //     const xhr = progressEvent.event.target
  //     const { responseText } = xhr
  //  }
});

export const axiosPrivateBase = axios.create({
  baseURL: process.env.REACT_APP_INTERFACE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});
