import { Box, Grid, Typography } from '@mui/material';
import ServiceTileSection from './ServiceTileSection';
import image2 from '../../../../../../assets/images/3-1-1.webp';
import image3 from '../../../../../../assets/french/appimg2.webp';
import image4 from '../../../../../../assets/french/appimg3.webp';

type Props = {
  isSmallScreen: boolean;
  isMediumScreen: boolean;
};
const ParallelSchema = ({ isSmallScreen, isMediumScreen }: Props) => {
  const renderImageBox = (
    image: string,
    borderType: 'all' | 'top' | 'bottom',
    style: React.CSSProperties = {}
  ) => (
    <Box
      style={{
        background: 'white !important',
        border: '1px solid lightgray',
        borderRadius: '10px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
        ...style,
      }}
    >
      <Box
        component="img"
        width="100%"
        src={image}
        style={{
          borderRadius: borderType === 'all' ? '9px' : 0,
          borderTopLeftRadius:
            borderType === 'top' || borderType === 'all' ? '9px' : 0,
          borderTopRightRadius:
            borderType === 'top' || borderType === 'all' ? '9px' : 0,
          borderBottomLeftRadius:
            borderType === 'bottom' || borderType === 'all' ? '9px' : 0,
          borderBottomRightRadius:
            borderType === 'bottom' || borderType === 'all' ? '9px' : 0,
          overflow: 'hidden',
        }}
      />
    </Box>
  );

  const renderTypography = (
    text: string,
    fontSize = '32px',
    style: React.CSSProperties = {}
  ) => (
    <p
      style={{
        color: '#005b2f',
        fontSize: '32px',
        fontFamily: 'Merriweather',
        marginBottom: '20px',
        paddingRight: '100px',
        ...style,
      }}
    >
      {text}
    </p>
  );

  return (
    <Grid
      container
      pt={isSmallScreen ? '100px' : isMediumScreen ? '150px' : '250px'}
      px={isSmallScreen ? '32px' : isMediumScreen ? '100px' : '160px'}
      pb="100px"
      spacing={1}
    >
      <Grid item xs={12} md={6}>
        <Box mb={40} />
        {renderTypography(
          "LaLoire vous renverra les articles pertinents et des extraits d'arrêts",
          '32px',
          { whiteSpace: 'pre-line', marginBottom: '50px' }
        )}
        <Box mb={30} />
        <Box>
          {renderImageBox(image3, 'top', {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            maxWidth: '500px',
            marginBottom: '40px',
          })}
          {renderImageBox(image4, 'bottom', {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            maxWidth: '500px',
            marginBottom: '40px',
          })}
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box mb={10} />
        {renderImageBox(image2, 'all', {
          maxWidth: '700px',
          marginBottom: '40px',
        })}
        <Box mb={30} />
        {renderTypography(
          'Vous recevrez une synthèse des sources et un aperçu de la juridique.'
        )}
        <Box mb={35} />
      </Grid>
      {/* <ServiceTileSection /> */}
      <Box mb={5} />
    </Grid>
  );
};

export default ParallelSchema;
