import { useEffect, useState } from 'react';
import './RulingPanel.css';
import { RulingModel } from '../../../../models/RulingModel';
import useTextHighlight from '../../../../hooks/useTextHighlight';
import { Box, styled } from '@mui/material';
import axios from '../../../../api/axios';
import { auth } from '../../../../configs/firebaseConfig';
import { getCssVariable } from 'styles/getVariables';
import LoadingWrapper from 'common/loading-wrapper/LoadingWrapper';

//ruling url examle: https://www.saos.org.pl/api/judgments/491500

type Props = {
  ruling: RulingModel;
  onHtmlTextSet: (htmlText: string) => void;
  htmlText: string | null;
  scrollToFragment: boolean;
};
const RulingPanel = ({
  ruling,
  onHtmlTextSet,
  htmlText,
  scrollToFragment,
}: Props) => {
  const { blockedText } = useTextHighlight();
  const [loading, setLoading] = useState(false);

  const StyledBox = styled(Box)(({ theme }) => ({
    userSelect: blockedText ? 'none' : 'text',
  }));

  const highlightedColor = getCssVariable('--highlighted-text-background');

  const fetchData = async () => {
    setLoading(true);

    const token = await auth?.currentUser?.getIdToken();
    await axios
      .get(
        `/lawmodel/rulings/text?ruling_link=${ruling.url}&start_index=${ruling.startIndex}&end_index=${ruling.endIndex}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            accept: '*',
            'content-type': 'application/json',
          },
          responseType: 'json',
        }
      )
      .then((res) => {
        if (res.data.length > 0) {
          let startIndex = res.data.indexOf('<RUL>') - 1;
          let endIndex = res.data.indexOf('</RUL>') - 5;

          let rulingDataHiglighted = highlightText(
            res.data.replace('<RUL>', '').replace('</RUL>', ''),
            startIndex,
            endIndex
          );
          let ruling = rulingDataHiglighted;

          if (ruling.includes('</p>') && ruling.includes('</div>')) {
            onHtmlTextSet(ruling);
          } else {
            let vcc = ruling
              .replace(/<|>/g, (m: string) => m + ' ')
              .replace(/[^\S\r\n]+/g, ' ');
            onHtmlTextSet('<p>' + vcc + '</p>');
          }
        }
      });
    setLoading(false);
  };

  useEffect(() => {
    if (!htmlText && htmlText !== '') {
      fetchData();
    }
    // else if (scrollToFragment) {
    //     const element = document.getElementById('overlay-span');
    //     if (element) {
    //         element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    //     }
    // }
    //eslint-disable-next-line
  }, [ruling]);

  useEffect(() => {
    if (loading === false) {
      const element = document.getElementById('overlay-span');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [loading]);

  function highlightText(html: string, ind1: number, ind2: number): string {
    let textCount = 0;
    let inTag = false;
    let result = '';
    let count = 0;
    for (let i = 0; i < html.length; i++) {
      if (html[i] === '<' && html[i + 1] !== ' ') {
        inTag = true;
        if (textCount >= ind1 && textCount < ind2) {
          result += '</span>';
        }
      }

      if (!inTag) {
        if (textCount === ind1) {
          if (count === 0) {
            result += `<span id="overlay-span" style="background-color:${highlightedColor};">`;
            count++;
          } else {
            result += `<span style="background-color:${highlightedColor};">`;
          }
        }
      }
      textCount++;
      result += html[i];

      if (html[i] === '>' && html[i - 1] !== ' ') {
        inTag = false;
        if (textCount > ind1 && textCount <= ind2) {
          result += `<span style="background-color:${highlightedColor};">`;
        }
      }
    }

    if (textCount >= ind1 && textCount <= ind2) {
      result += '</span>';
    }

    return result;
  }

  return (
    <div>
      <LoadingWrapper
        isLoading={loading}
        loadingHeight="300px"
        description="Ładowanie orzeczenia..."
      >
        <StyledBox>
          <Box
            sx={{
              marginBottom: '10px',
            }}
          >
            <h3>{ruling.title + ' - Pełny tekst orzeczenia'}</h3>
          </Box>
          {htmlText && <div dangerouslySetInnerHTML={{ __html: htmlText }} />}
        </StyledBox>
      </LoadingWrapper>
    </div>
  );
};
export default RulingPanel;
