import React, { useState, createContext } from 'react';

interface PropsBasicDataProvider {
  children: React.ReactNode;
}

interface ContextDataModel {
  blockedText: boolean;
  setBlockedText: (blocked: boolean) => void;
}

export const TextHighlightContext: React.Context<ContextDataModel> =
  createContext({
    blockedText: false as boolean,
    setBlockedText: (blocked: boolean) => {},
  });

export const TextHighlightProvider: any = ({
  children,
}: PropsBasicDataProvider) => {
  const [blocked, setBlocked] = useState<boolean>(false);

  const setBlockedText = (b: boolean) => {
    setBlocked(b);
  };

  const contextValue = {
    blockedText: blocked,
    setBlockedText: setBlockedText,
  };

  return (
    <TextHighlightContext.Provider value={contextValue}>
      {children}
    </TextHighlightContext.Provider>
  );
};
