import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Outlet } from 'react-router-dom';
import NavBar from './navbar/NavBar';
import NavBarFrench from './navbar/FrenchNavBar';
import styles from './styles.module.scss';

const PublicLayout = () => {
  return (
    <Box className={styles.container}>
      <CssBaseline />
      <Box>
        <Box component="main">
          {process.env.REACT_APP_LANGUAGE === 'fr' ? (
            <NavBarFrench />
          ) : (
            <NavBar />
          )}
          <Box className={styles.content}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PublicLayout;
