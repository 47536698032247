import { IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

type Props = {
  page: number;
  pageCount: number;
  onPageChange: (page: number) => void;
};

const PaginationButtons = ({ page, pageCount, onPageChange }: Props) => {
  return (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      <p
        style={{ color: 'var(--text-primary)', paddingBottom: '3px' }}
      >{`${page}/${pageCount}`}</p>
      <IconButton onClick={() => onPageChange(page - 1)} disabled={page <= 1}>
        <ArrowBackIosIcon
          sx={{
            color: page <= 1 ? 'var(--text-disabled)' : 'var(--text-primary)',
            fontSize: '20px',
          }}
        />
      </IconButton>

      <IconButton
        onClick={() => onPageChange(page + 1)}
        disabled={page >= pageCount}
      >
        <ArrowForwardIosIcon
          sx={{
            color:
              page >= pageCount
                ? 'var(--text-disabled)'
                : 'var(--text-primary)',
            fontSize: '20px',
          }}
        />
      </IconButton>
    </div>
  );
};

export default PaginationButtons;
