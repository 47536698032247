import styles from './styles.module.scss';

const RodoPage = () => {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {' '}
      <div className={styles.policyDiv}>
        <h2>Polityka Prywatności</h2>
        <div className={styles.statuteContainer}>
          <h4 className={styles.titleH4}>1. Definicje</h4>

          <p>
            <strong>Administrator</strong> – PrawMi PSA z siedzibą w Warszawie,
            ul. Orla 6/37, 00-143 Warszawa, wpisana do Krajowego Rejestru
            Przedsiębiorców przez Sąd Rejonowy w Warszawie, XII Wydział
            Gospodarczy KRS pod nr 0001131953.
          </p>

          <p>
            <strong>Dane osobowe</strong> – informacje o osobie fizycznej
            zidentyfikowanej lub możliwej do zidentyfikowania poprzez jeden bądź
            kilka szczególnych czynników określających lub umożliwiających
            samodzielnie bądź wspólnie ustalenie jej tożsamości, w tym m.in.
            imię, nazwisko, IP urządzenia, identyfikator internetowy oraz
            informacje gromadzone za pośrednictwem plików cookie oraz innej
            podobnej technologii.
          </p>
          <p>
            <strong>Polityka</strong> – niniejsza Polityka prywatności.
          </p>
          <p>
            <strong>RODO</strong> – Rozporządzenie Parlamentu Europejskiego i
            Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
            fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
            swobodnego przepływu takich danych oraz uchylenia dyrektywy
            95/46/WE.
          </p>
          <p>
            <strong>Aplikacja </strong>– serwis określony w § 2 Regulaminu.
          </p>
          <p>
            <strong>Serwis </strong> – strona www prawmi.pl dostępna z poziomu
            Użytkownika niezarejestrowanego.
          </p>
          <p>
            <strong>Regulamin </strong>– regulamin Aplikacji dostępny pod
            domeną: prawmi.pl/regulamin
          </p>
          <p>
            <strong>Użytkownik </strong> – osoba prawna, jednostka organizacyjna
            nieposiadająca osobowości prawnej lub pełnoletnia osoba fizyczna
            korzystająca z Aplikacji w celu związanym bezpośrednio z jej
            działalnością gospodarczą lub zawodową, na rzecz której zawarta
            została umowa o świadczenie Usług określonych w § 7 Regulaminu.
          </p>
          <p>
            <strong>Użytkownik niezarejestrowany </strong>- osoba odwiedzającą
            Serwis i przeglądająca go z poziomu osoby, która nie dokonała
            rejestracji w Aplikacji.
          </p>

          <h4 className={styles.titleH4}>2. Przetwarzanie danych</h4>

          <div className={styles.numberedParagraph}>
            <div className={styles.number}>
              <p>2.1</p>
            </div>
            <p>
              W związku z korzystaniem przez Użytkownika z Aplikacji oraz
              dostępu do Serwisu przez Użytkownika niezarejestrowanego
              Administrator zbiera dane odpowiednio w zakresie niezbędnym do
              świadczenia oferowanych usług, a także informacje o aktywności
              Użytkownika w Aplikacji oraz Serwisie. Poniżej zostały opisane
              szczegółowe zasady oraz cele przetwarzania Danych osobowych
              gromadzonych podczas korzystania z Aplikacji i Serwisu przez
              Użytkowników i Użytkowników niezarejestrowanych.
            </p>
          </div>

          <h4 className={styles.titleH4}>3. Bezpieczeństwo Danych osobowych</h4>
          <div className={styles.numberedParagraph}>
            <div className={styles.number}>
              <p>3.1</p>
            </div>
            <p>
              Administrator zapewnia techniczne i organizacyjne środki ochrony
              danych, w tym w szczególności Danych osobowych. Administrator na
              bieżąco prowadzi analizę ryzyka w celu zapewnienia, że Dane
              osobowe przetwarzane są przez niego w sposób bezpieczny –
              zapewniający przede wszystkim, że dostęp do danych mają jedynie
              osoby upoważnione i jedynie w zakresie, w jakim jest to niezbędne
              ze względu na wykonywane przez nie zadania. Administrator dba o
              to, by wszystkie operacje na danych osobowych były rejestrowane i
              dokonywane jedynie przez uprawnionych pracowników i
              współpracowników.
            </p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.number}>
              <p>3.2</p>
            </div>
            <p>
              Administrator podejmuje wszelkie niezbędne działania, by także
              jego podwykonawcy i inne podmioty współpracujące dawały gwarancję
              stosowania odpowiednich środków bezpieczeństwa w każdym przypadku,
              gdy przetwarzają Dane osobowe na zlecenie Administratora.
            </p>
          </div>
          <h4 className={styles.titleH4}>
            4. Cele oraz podstawy prawne przetwarzania danych
          </h4>
          <div className={styles.numberedParagraph}>
            <div className={styles.number}>
              <p>4.1.</p>
            </div>
            <p>
              Dane Użytkowników niezarejestrowanych zbierane poprzez załadowanie
              Serwisu i zapoznawanie się z jego treścią to m.in. adres IP,
              informacja o przeglądarce, data i godzina korzystania z serwisu
              oraz czynności podejmowane w Serwisie.
            </p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.number}>
              <p>4.2.</p>
            </div>
            <p>
              Dane osobowe Użytkownika oraz Użytkownika niezarejestrowanego, w
              tym dane przekazane w związku z wypełnieniem formularza w procesie
              zawierania umów związanych z dostępem o Aplikacji (tj. np. imię,
              nazwisko, adres zamieszkania, numer telefonu), adres IP lub inne
              identyfikatory oraz informacje gromadzone za pośrednictwem plików
              cookies lub innych podobnych technologii (w przypadku Usługi
              dostępu do Aplikacji określonej w § 2 Regulaminu) przetwarzane są
              przez Administratora tylko w przypadkach prawnie dozwolonych, na
              warunkach wynikających z przepisów prawa:
            </p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.numberIndented}>
              <p>4.2.1.</p>
            </div>
            <p>
              w celu wykonania umowy o świadczenie Usług dostępu do Aplikacji
              określonych w § 7 Regulaminu, której stroną jest Użytkownik oraz
              do podjęcia działań na żądanie Użytkownika przed zawarciem umowy –
              wówczas podstawą prawną przetwarzania jest niezbędność do
              wykonania umowy, której stroną jest osoba, której dane dotyczą,
              lub do podjęcia działań na żądanie osoby, której dane dotyczą,
              przed zawarciem umowy (art. 6 ust. 1 lit. b RODO). Dane są
              przetwarzane przez okres podejmowania działań przed zawarciem
              umowy oraz przez okres jej obowiązywania;
            </p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.numberIndented}>
              <p>4.2.2.</p>
            </div>
            <p>
              w celu wykonania obowiązków prawnych ciążących na Administratorze,
              w tym wynikających z przepisów o rachunkowości, przepisów
              podatkowych, przepisów o przeciwdziałaniu praniu pieniędzy i
              finansowaniu terroryzmu (art. 6 ust. 1 lit. c RODO). Dane są
              przetwarzane przez okres niezbędny do wykonania obowiązków
              prawnych ciążących na Administratorze, przy czym, jeżeli został
              określony w odpowiednich przepisach, przez taki okres.
            </p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.numberIndented}>
              <p>4.2.3.</p>
            </div>
            <p>
              w celach analitycznych i statystycznych oraz na potrzeby
              zapewnienia bezpieczeństwa i zarządzania Aplikacją oraz Serwisem –
              wówczas podstawą prawną przetwarzania jest uzasadniony interes
              Administratora (art. 6 ust. 1 lit. f RODO) polegający na
              prowadzeniu analiz aktywności Użytkowników oraz Użytkowników
              niezarejestrowanych, a także ich preferencji w celu poprawy
              stosowanych funkcjonalności i Usług. Dane są przetwarzane przez
              okres niezbędny do realizacji tego celu, lecz nie dłużej niż do
              chwili zakończenia obowiązywania umowy o świadczenie Usługi lub
              wniesienia przez Użytkownika lub Użytkownika niezarejestrowanego
              sprzeciwu;
            </p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.numberIndented}>
              <p>4.2.4.</p>
            </div>
            <p>
              w celu dochodzenia roszczeń lub obrony przed nimi – podstawą
              prawną przetwarzania jest uzasadniony interes Administratora (art.
              6 ust. 1 lit. f RODO) polegający na ochronie jego praw. Dane są
              przetwarzane przez okres niezbędny do realizacji tego celu, lecz
              nie dłużej niż do chwili przedawnienia roszczeń lub wniesienia
              przez Użytkownika lub Użytkownika niezarejestrowanego sprzeciwu;
            </p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.numberIndented}>
              <p>4.2.5.</p>
            </div>
            <p>
              w celach marketingowych Administratora – Administrator przetwarza
              dane osobowe Użytkowników oraz Użytkowników niezarejestrowanych w
              celu realizowania działań marketingowych, polegających na
              kierowaniu e-mailowych powiadomień o interesujących ofertach lub
              treściach, o ile dany Użytkownik wyraził na to zgodę. W takim
              przypadku dane osobowe są przetwarzane w celu kierowania do danego
              Użytkownika treści marketingowych, np. w ramach newslettera –
              podstawą prawną przetwarzania jest prawnie uzasadniony interes
              Administratora (art. 6 ust. 1 lit f RODO) w związku z wyrażoną
              zgodą na otrzymywanie informacji marketingowych, na okres
              niezbędny do realizacji tego celu, lecz nie dłużej niż do momentu
              cofnięcia zgody lub wniesienia przez danego Użytkownika sprzeciwu.
            </p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.number}>
              <p>4.3.</p>
            </div>
            <p>
              Jeżeli Użytkownik bądź Użytkownik niezarejestrowany zamieszcza w
              Aplikacji lub Serwisie jakiekolwiek Dane osobowe innych osób (w
              tym np. ich imię i nazwisko, adres, numer telefonu lub adres
              e-mail), może to uczynić jedynie pod warunkiem nienaruszenia
              przepisów prawa i dóbr osobistych tych osób.
            </p>
          </div>

          <h4 className={styles.titleH4}>
            5. Pliki cookies i podobna technologia
          </h4>
          <div className={styles.numberedParagraph}>
            <div className={styles.number}>
              <p>5.1. </p>
            </div>
            <p>
              Pliki cookies to małe pliki tekstowe, które są umieszczane na
              urządzeniu Użytkownika. Zbierają one dane ułatwiające korzystanie
              z witryny, takie jak informacje o logowaniu czy preferencjach
              językowych. Administrator stosuje zarówno własne pliki cookies,
              jak i te pochodzące od zewnętrznych podmiotów, czyli z innych
              domen niż odwiedzana strona, głównie w celach analitycznych i
              reklamowych. Aplikacja i Serwis używają plików cookies przede
              wszystkim do zapewnienia poprawnego działania strony,
              zapamiętywania wyborów Użytkowników, a po wyrażeniu zgody także do
              analizy ruchu i dopasowywania reklam do zainteresowań.
            </p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.number}>
              <p>5.2.</p>
            </div>
            <p>Pliki cookies wykorzystywane w tym celu obejmują:</p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.numberIndented}>
              <p>5.2.1.</p>
            </div>
            <p>
              pliki cookies z danymi wprowadzanymi przez Użytkownika lub
              Użytkownika niezarejestrowanego (identyfikator sesji) na czas
              trwania sesji (ang. user input cookies);
            </p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.numberIndented}>
              <p>5.2.2.</p>
            </div>
            <p>
              uwierzytelniające pliki cookies wykorzystywane do usług
              wymagających uwierzytelniania na czas trwania sesji (ang.
              authentication cookies);
            </p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.numberIndented}>
              <p>5.2.3.</p>
            </div>
            <p>
              pliki cookies służące do zapewnienia bezpieczeństwa, np.
              wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania
              (ang. user centric security cookies);
            </p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.numberIndented}>
              <p>5.2.4.</p>
            </div>
            <p>
              trwałe pliki cookies służące do personalizacji interfejsu na czas
              trwania sesji lub nieco dłużej (ang. user interface customization
              cookies).
            </p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.number}>
              <p>5.3.</p>
            </div>
            <p>
              Wykorzystanie plików cookies w celu zbierania za ich pośrednictwem
              danych, w tym uzyskania dostępu do danych zapisanych na urządzeniu
              Użytkownika, wymaga uzyskania zgody Użytkownika lub Użytkownika
              niezarejestrowanego. Zgoda ta może być w każdym momencie wycofana.
              Zezwolenie nie jest wymagane jedynie w przypadku plików cookies,
              których stosowanie jest niezbędne do świadczenia usługi
              telekomunikacyjnej (transmisja danych w celu wyświetlenia treści).
              Wycofanie zgody na wykorzystanie plików cookies możliwe jest za
              pośrednictwem ustawień przeglądarki.
            </p>
          </div>

          <h4 className={styles.titleH4}>6. Uprawnienia użytkownika</h4>
          <div className={styles.numberedParagraph}>
            <div className={styles.number}>
              <p>6.1.</p>
            </div>
            <p>
              Użytkownikowi oraz Użytkownikowi niezarejestrowanemu przysługuje
              prawo: dostępu do treści danych oraz żądania ich sprostowania,
              usunięcia, ograniczenia przetwarzania, prawo do przenoszenia
              danych oraz prawo wniesienia sprzeciwu względem przetwarzania
              danych, a także prawo wniesienia skargi do Prezesa Urzędu Ochrony
              Danych Osobowych. W zakresie, w jakim dane przetwarzane są na
              podstawie zgody, można ją wycofać w dowolnym momencie, kontaktując
              się z Administratorem.
            </p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.number}>
              <p>6.2.</p>
            </div>
            <p>
              Każdy Użytkownik ma prawo zgłoszenia sprzeciwu względem
              przetwarzania danych dla celów marketingowych, jeśli przetwarzanie
              odbywa się w związku z uzasadnionym interesem Administratora, a
              także – z przyczyn związanych ze szczególną sytuacją Użytkownika –
              w innych przypadkach, gdy postawą prawną przetwarzania danych jest
              uzasadniony interes Administratora (np. w związku z realizacją
              celów analitycznych i statystycznych).
            </p>
          </div>

          <h4 className={styles.titleH4}>7. Odbiorcy danych</h4>

          <div className={styles.numberedParagraph}>
            <div className={styles.number}>
              <p>7.1.</p>
            </div>
            <p>
              W związku z realizacją usług odbiorcami Danych osobowych będą w
              niezbędnym zakresie pracownicy i współpracownicy Administratora,
              organy publiczne, podmioty przetwarzające dane osobowe w imieniu
              Administratora na podstawie umowy powierzenia przetwarzania danych
              osobowych (w tym świadczące usługi księgowe, doradztwa
              podatkowego, doręczania przesyłek, usług IT, narzędzi
              analitycznych i marketingowych).
            </p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.number}>
              <p>7.2.</p>
            </div>
            <p>
              Dane osobowe przetwarzane przez Administratora, o ile zostaną
              przekazane bezpośrednio przez Użytkownika poprzez Aplikację w
              zakresie wprowadzanych zapytań przetwarzanych przez sztuczną
              inteligencję, mogą być przekazywane do dostawców usług
              zapewniających silniki zasilające (API) rozwijaną przez
              Administratora sztuczną inteligencję, za pośrednictwem której
              Administrator świadczy Usługi w Aplikacji, tj. do OpenAI Ireland
              Ltd . OpenAI Ireland Ltd przetwarza dane osobowe na podstawie umów
              o powierzeniu przetwarzania danych osobowych zawartych z
              Administratorem.
            </p>
          </div>

          <h4 className={styles.titleH4}>8. Przekazywanie danych poza EOG</h4>
          <div className={styles.numberedParagraph}>
            <div className={styles.number}>
              <p>8.1.</p>
            </div>
            <p>
              Poziom ochrony Danych osobowych poza Europejskim Obszarem
              Gospodarczym (EOG) różni się od tego zapewnianego przez prawo
              europejskie. Z tego powodu Administrator może przekazywać Dane
              osobowe poza EOG tylko wtedy, gdy jest to konieczne i z
              zapewnieniem odpowiedniego stopnia ochrony, przede wszystkim
              poprzez:
            </p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.numberIndented}>
              <p>8.1.1.</p>
            </div>
            <p>
              współpracę z podmiotami przetwarzającymi Dane osobowe w państwach,
              w odniesieniu do których została wydana stosowna decyzja Komisji
              Europejskiej dotycząca stwierdzenia zapewnienia odpowiedniego
              stopnia ochrony Danych osobowych;
            </p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.numberIndented}>
              <p>8.1.2.</p>
            </div>
            <p>
              stosowanie standardowych klauzul umownych wydanych przez Komisję
              Europejską; wraz z wymaganymi dodatkowymi środkami bezpieczeństwa
              zapewniają one Danym osobowym taką samą ochronę, jaka przysługuje
              im w Unii Europejskiej;
            </p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.numberIndented}>
              <p>8.1.3.</p>
            </div>
            <p>
              stosowanie wiążących reguł korporacyjnych, zatwierdzonych przez
              właściwy organ nadzorczy,
            </p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.number}>
              <p>8.2.</p>
            </div>
            <p>
              Administrator informuje o zamiarze przekazania Danych osobowych
              poza EOG na etapie ich zbierania.
            </p>
          </div>
          <h4 className={styles.titleH4}>
            9. Dane kontaktowe i zmiany Polityki
          </h4>
          <div className={styles.numberedParagraph}>
            <div className={styles.number}>
              <p>9.1.</p>
            </div>
            <p>
              Kontakt z Administratorem jest możliwy poprzez adres e-mail:
              biuro@prawmi.pl
            </p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.number}>
              <p>9.2.</p>
            </div>
            <p>
              Aktualna wersja Polityki obowiązuje od dnia 16 października 2024
              r.
            </p>
          </div>
          <div className={styles.numberedParagraph}>
            <div className={styles.number}>
              <p>9.3.</p>
            </div>
            <p>
              Polityka jest na bieżąco weryfikowana i w razie potrzeby będzie
              aktualizowana.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RodoPage;
