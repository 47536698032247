import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import styles from './styles.module.scss';
import SingleLineSchema from './components/SingleLineSchema';
import ParallelSchema from './components/ParallelSchema';

const DescriptionSchema: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <>
      <div
        style={{
          marginTop: '-100px',
          background: 'var(--background-start-primary)',
        }}
      >
        <div className={styles.container}>
          <svg className={styles.svgImg} viewBox="0 0 500 100" width="100%">
            <path
              d="M0,0 C150,100 350,0 500,30 L500,0 L0,0 Z"
              style={{ fill: '#d9d9d9' }}
            />
          </svg>

          {isSmallScreen ? (
            <SingleLineSchema />
          ) : (
            <ParallelSchema
              isSmallScreen={isSmallScreen}
              isMediumScreen={isMediumScreen}
            />
          )}

          <svg
            className={styles.svgImg2}
            viewBox="0 0 500 50"
            width="100%"
            style={{
              transform: 'rotate(180deg)',
              position: 'absolute',
              bottom: 0,
            }}
          >
            <path
              d="M0,0 C150,50 350,0 500,15 L500,0 L0,0 Z"
              style={{ fill: '#005b2f' }}
            />
          </svg>
        </div>
      </div>
      <div
        style={{ marginTop: '-30px', background: '#005b2f', height: '100px' }}
      ></div>
    </>
  );
};

export default DescriptionSchema;
