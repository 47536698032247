import { createContext } from 'react';

const SplitPaneContext = createContext({
  onMouseHoldDown: (event: React.MouseEvent<Element, MouseEvent>) => {},
  clientHeight: null,
  setClientHeight: () => {},
  clientWidth: null,
  setClientWidth: () => {},
} as {
  onMouseHoldDown: (event: React.MouseEvent<Element, MouseEvent>) => void;
  clientHeight: number | null;
  setClientHeight: (clientHeight: number) => void;
  clientWidth: number | null;
  setClientWidth: (clientWidth: number) => void;
});

export default SplitPaneContext;
