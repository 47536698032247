import React, { useEffect, useState } from 'react';
import { ConversationModel } from '../../../../models/ConversationModel';
import { Box, Tooltip, Typography } from '@mui/material';
import { sortConversations } from './ListOfConversations';
import ConversationSidebarButton from './ConversationSidebarButton';
import { useTranslation } from 'react-i18next';

type Props = {
  conversationList: ConversationModel[];
  onClick: (conversation: ConversationModel) => void;
};

export const SidebarListOfConversations = ({
  conversationList,
  onClick,
}: Props) => {
  const [todaysConversations, setTodaysConversations] = useState<
    ConversationModel[]
  >([]);
  const [yesterdaysConversations, setYesterdaysConversations] = useState<
    ConversationModel[]
  >([]);
  const [thisWeeksConversations, setThisWeeksConversations] = useState<
    ConversationModel[]
  >([]);
  const [olderConversations, setOlderConversations] = useState<
    ConversationModel[]
  >([]);
  const { t } = useTranslation();

  useEffect(() => {
    let output = sortConversations(conversationList);

    setTodaysConversations(output.todays);
    setYesterdaysConversations(output.yesterdays);
    setThisWeeksConversations(output.thisWeeks);
    setOlderConversations(output.older);
    //eslint-disable-next-line
  }, [conversationList]);

  const renderConversations = (
    title: string,
    conversations: ConversationModel[]
  ) => {
    if (conversations.length === 0) return null;
    else {
      return (
        <Box marginBottom={'1px'}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              style={{ fontSize: '14px', fontWeight: '700', color: '#f0f0f0' }}
            >
              {title}
            </Typography>
          </div>
          {conversations.map((conversation, index) => (
            <Tooltip
              title={conversation.title + '...'}
              placement="right"
              key={index}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <ConversationSidebarButton
                  index={index}
                  conversation={conversation}
                  onDeleteClick={(c) => {}}
                  onClick={(c) => {
                    onClick(c);
                  }}
                />
              </Box>
            </Tooltip>
          ))}
        </Box>
      );
    }
  };

  return (
    <Box>
      {renderConversations(t('dashboard:sidebar.today'), todaysConversations)}
      {renderConversations(
        t('dashboard:sidebar.yesterday'),
        yesterdaysConversations
      )}
      {renderConversations(
        t('dashboard:sidebar.thisWeek'),
        thisWeeksConversations
      )}
      {renderConversations(t('dashboard:sidebar.older'), olderConversations)}
    </Box>
  );
};
