import { Button } from '@mui/material';
import MuiLink from '@mui/material/Link';
import React from 'react';
import { useNavigate } from 'react-router';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <footer>
        <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-b py-30">
          <div style={{ height: '100px' }}></div>

          <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
              <Button
                variant="text"
                sx={{
                  textTransform: 'none',
                  '&:hover': {
                    background: 'transparent',
                  },
                }}
                onClick={() => navigate('/polityka')}
                className=" hover:text-gray-900"
              >
                Polityka prywatności
              </Button>
              <Button
                variant="text"
                sx={{
                  textTransform: 'none',
                  '&:hover': {
                    background: 'transparent',
                  },
                }}
                onClick={() => navigate('/regulamin')}
                className=" hover:text-gray-900"
              >
                Regulamin
              </Button>
              <div className="text-sm text-gray-200 font-semibold py-1">
                &copy; {new Date().getFullYear()}
                {'  '}
                {/* <MuiLink
                                    href="#"
                                    className=" hover:text-gray-900"
                                >
                                    PrawMi Sp. z o. o.
                                </MuiLink> */}
                <MuiLink href="#" className=" hover:text-gray-900">
                  PrawMi
                </MuiLink>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
