import { useContext } from 'react';
import { SidebarContext } from '../context/SidebarProvider';
import { ToastModel } from '../models/ToastModel';

const useSidebar = () => {
  const {
    toasts,
    addToast: originalAddToast,
    removeToast,
  } = useContext(SidebarContext);

  const addToast = (toast: ToastModel) => {
    originalAddToast(toast);
  };
  return { toasts, addToast, removeToast };
};
export default useSidebar;
