import { Box } from '@mui/material';
import style from './searchTabPanel.module.scss';
import SearchLawPanel from '../search-law-panel/SearchLawPanel';
import SwitchButtons from '../../atoms/switch-buttons/SwitchButtons';
import { useState } from 'react';
import SearchRulingPanel from '../search-ruling-panel/SearchRulingPanel';
import { useTranslation } from 'react-i18next';

const SearchTabPanel = () => {
  const { t } = useTranslation();
  const [showLawSearch, setShowLawSearch] = useState<boolean>(true);

  const handleSwitchChange = (value: 'one' | 'two') => {
    setShowLawSearch(value === 'one');
  };

  return (
    <Box className={style.mainDiv}>
      <Box marginTop={'20px'}>
        <SwitchButtons
          titleOne={t('dashboard:searchTabPanel.law')}
          titleTwo={t('dashboard:searchTabPanel.ruling')}
          onChange={handleSwitchChange}
          value={showLawSearch ? 'one' : 'two'}
        />
      </Box>
      <Box marginTop={'20px'}>
        {showLawSearch ? <SearchLawPanel /> : <SearchRulingPanel />}
      </Box>
      {/* <Box marginTop={"30px"}>
        <SearchLawPanel />
      </Box> */}
    </Box>
  );
};

export default SearchTabPanel;
