import { LawModel, lawFromMap } from '../models/LawModel';
import { RulingModel, rulingFromMap } from '../models/RulingModel';
import axios from './axios';

export const getConversationId = async (
  token: string,
  question: string,
  conversationId?: string | undefined
): Promise<string> => {
  let output = '';

  let query = {
    query: {
      conversation_id: conversationId,
      user_message: question,
    },
  };

  await axios
    .post('interface', query, {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: '*',
        'content-type': 'application/json',
      },
    })
    .then((response) => {
      output = response.data.conversation_id;
    });
  return output;
};

export const getArticlesAsSources = async (
  token: string,
  conversationId: string
): Promise<LawModel[]> => {
  let sources: LawModel[] = [];
  await axios
    .get(`interface/articles/${conversationId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: '*',
        'content-type': 'application/json',
      },
    })
    .then((response) => {
      if (response.data.articles) {
        response.data.articles.forEach((model: any) => {
          let law: LawModel = lawFromMap(model);
          law.rulings = [];
          law.rulingsLoading = true;
          sources.push(law);
        });
      }
    });
  return sources;
};

export const getRulingsAsSources = async (
  token: string,
  conversationId: string,
  article: LawModel
): Promise<RulingModel[]> => {
  let sources: RulingModel[] = [];
  console.log('eeee');

  if (article.pathJson) {
    await axios
      .get(
        `interface/rulings/${conversationId}?path_json=${encodeURIComponent(
          article.pathJson
        )}&act_unified_url=${encodeURIComponent(article.url || '')}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            accept: '*',
            'content-type': 'application/json',
          },
        }
      )
      .then((response) => {
        if (response.data.rulings) {
          response.data.rulings.forEach((model: any) => {
            let ruling: RulingModel = rulingFromMap(model);
            sources.push(ruling);
          });
        }
      });
  }
  return sources;
};

export const getRandomRulings = async (
  conversationId: string | undefined,
  token: string
) => {
  if (!conversationId) {
    return [];
  }

  console.log('getRandomRulingsconversationId', conversationId);
  const response = await axios.get(
    `interface/rulings/random/${conversationId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: '*',
        'content-type': 'application/json',
      },
    }
  );

  console.log('getRandomRulingsresponse3', response.data);
  let output: RulingModel[] = response.data.rulings.map((ruling: any) =>
    rulingFromMap(ruling)
  );

  if (response.status !== 200) {
    console.log('getRandomRulingsresponse3', response);
    throw new Error('Failed to fetch random rulings');
  }

  return output;
};

export const getDocumentConversationId = async (
  token: string,
  question: string,
  conversationId?: string | undefined
): Promise<string> => {
  let output = '';

  let query = {
    query: {
      document_id: conversationId,
      user_message: question,
    } /* dummy change to make a commit. (delete it) */,
  };

  await axios
    .post('documents', query, {
      headers: {
        Authorization: `Bearer ${token}`,
        accept: '*',
        'content-type': 'application/json',
      },
    })
    .then((response) => {
      output = response.data.document_id;
    });
  return output;
};

export const getDocumentStatus = async (
  token: string,
  docId: string
): Promise<any> => {
  const response = await axios.get(`documents/document/status/${docId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const getDocumentContent = async (
  token: string,
  docId: string,
  onChunk?: (chunk: string) => void
): Promise<any> => {
  const baseUrl = axios.defaults.baseURL?.replace(/\/$/, '') || '';
  const url = `${baseUrl}/documents/document/${docId}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'text/event-stream',
        'Cache-Control': 'no-cache',
      },
      credentials: 'same-origin',
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reader = response.body!.getReader();
    const decoder = new TextDecoder('utf-8');
    let buffer = '';
    let accumulatedText = '';

    while (true) {
      const { value, done } = await reader.read();
      if (done) break;

      const text = decoder.decode(value, { stream: true });
      buffer += text;

      const lines = buffer.split('\n');
      buffer = lines.pop() || '';

      for (const line of lines) {
        if (line.startsWith('data: ')) {
          const chunk = line.slice(6);
          if (chunk === '[DONE]') continue;

          const formattedChunk = chunk.replace(/\\n/g, '\n');
          accumulatedText = formattedChunk;
          onChunk?.(formattedChunk);
        }
      }
    }

    if (buffer.startsWith('data: ')) {
      const chunk = buffer.slice(6);
      if (chunk !== '[DONE]') {
        const formattedChunk = chunk.replace(/\\n/g, '\n');
        accumulatedText = formattedChunk;
        onChunk?.(formattedChunk);
      }
    }

    return accumulatedText;
  } catch (error) {
    console.error('Document streaming error:', error);
    throw error;
  }
};
