import './loadingText.scss';

type Props = {
  text?: string;
};

const LoadingText = ({ text }: Props) => {
  return (
    <>
      <p className="animate-charcter">{text}</p>
    </>
  );
};

export default LoadingText;

// const LoadingText = ({ text }: Props) => {
//   return (
//     <>
//       <div className="patterns">
//         <svg width="100%" height="100px">
//           <text y="20px">{text}</text>
//         </svg>
//       </div>
//     </>
//   );
// };

// export default LoadingText;
