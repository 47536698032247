import { useContext } from 'react';
import { InstructionContext } from '../context/InstructionProvider';

const useInstruction = () => {
  const {
    showInstruction: showInstruction,
    setShowInstruction: setShowInstruction,
  } = useContext(InstructionContext);
  return { showInstruction, setShowInstruction };
};
export default useInstruction;
