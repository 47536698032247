import {
  Box,
  Button,
  Checkbox,
  CheckboxProps,
  Grid,
  Modal,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
  styled,
} from '@mui/material';
import React, { useState } from 'react';
import colors from '../../../../../configs/colorConfig';
import { firestore } from '../../../../../configs/firebaseConfig';
import { addDoc, collection } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from './styles.module.scss';
import OfficeLink from 'module-public/components/atoms/office-link/OfficeLink';
import { getCssVariable } from 'styles/getVariables';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid lightGray',
  borderRadius: '10px',
  boxShadow: 24,
  p: 2,
};

const StyledCheckbox = styled(Checkbox)<CheckboxProps>({
  color: getCssVariable('--text-secondary'), // Default color
  '&.Mui-checked': {
    color: getCssVariable('--secondary'), // Checked color
  },
  '&:hover': {
    color: getCssVariable('--textfield-border-hover'), // Hover color
  },
});

const StyledTextField = styled(TextField)<TextFieldProps>({
  marginBottom: '0px',
  '& .MuiInputBase-input': {
    color: getCssVariable('--text-secondary'),
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: getCssVariable('--textfield-border'),
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: getCssVariable('--text-secondary'),
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: getCssVariable('--textfield-border-focused'),
  },
});

const FormField: React.FC<TextFieldProps> = (props) => {
  return (
    <StyledTextField variant="standard" size="small" fullWidth {...props} />
  );
};

const FieldError = styled(Typography)<TypographyProps>({
  color: colors.error,
  fontSize: '0.75rem',
  marginLeft: '0.5rem',
});

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  question: string;
}

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const validatePhoneNumber = (phoneNumber: string) => {
  return String(phoneNumber)
    .toLowerCase()
    .match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g);
};

const validateQuestion = (question: string) => {
  if (question.length === 0) {
    return 'Pole jest wymagane';
  } else if (question.length < 3) {
    return 'Treść pytania jest za krótka';
  } else if (question.length > 1000) {
    return 'Treść pytania jest za długa';
  } else {
    return '';
  }
};

const AskQuestionForm = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  const [data, setData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    question: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [termsAcceptedOne, setTermsAcceptedOne] = useState<boolean>(false);
  const [termsAcceptedTwo, setTermsAcceptedTwo] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [dataError, setDataError] = useState<any>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    question: '',
    termsAcceptedOne: true,
    termsAcceptedTwo: true,
  });

  const checkFields = () => {
    let everythingIsCorrect = true;
    let errors = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      question: '',
      termsAcceptedOne: true,
      termsAcceptedTwo: true,
    };
    if (data.firstName === '') {
      errors = { ...errors, firstName: t('start:form.required') };
      everythingIsCorrect = false;
    }
    if (data.lastName === '') {
      errors = { ...errors, lastName: t('start:form.required') };
      everythingIsCorrect = false;
    }
    if (data.email === '') {
      errors = { ...errors, email: t('start:form.required') };
      everythingIsCorrect = false;
    }
    if (
      validatePhoneNumber(data.phoneNumber) == null &&
      data.phoneNumber !== ''
    ) {
      errors = { ...errors, phoneNumber: t('start:form.wrongPhone') };
      everythingIsCorrect = false;
    }
    if (validateEmail(data.email) == null) {
      errors = { ...errors, email: t('start:form.fieldIncorrect') };
      everythingIsCorrect = false;
    }
    if (validateQuestion(data.question) !== '') {
      errors = { ...errors, question: validateQuestion(data.question) };
      everythingIsCorrect = false;
    }
    if (!termsAcceptedOne) {
      errors = { ...errors, termsAcceptedOne: false };
      everythingIsCorrect = false;
    }
    if (!termsAcceptedTwo) {
      errors = { ...errors, termsAcceptedTwo: false };
      everythingIsCorrect = false;
    }
    setDataError({ ...errors });

    return everythingIsCorrect;
  };

  const onSubmit = async () => {
    let output = checkFields();
    if (output) {
      setShowModal(true);
      setIsLoading(true);
      await addDoc(collection(firestore, 'Inquiries'), {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        question: data.question,
      });
      setData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        question: '',
      });
      setIsLoading(false);
    }
  };

  const handleTextFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    filed: string
  ) => {
    setData({ ...data, [filed]: e.target.value });
    setDataError({ ...dataError, [filed]: '' });
  };

  return (
    <>
      <Grid
        className={styles.container}
        container
        px={isMd ? 4 : 21}
        id="kontakt"
      >
        <Grid item xs={12} md={5} className={styles.descriptionContainer}>
          <p className={styles.title}>{t('start:form.title')}</p>
          <p className={styles.descriptionText}>{t('start:form.text')}</p>

          <Box className={styles.linkContainer}>
            <OfficeLink showIcon={true} />
          </Box>
        </Grid>

        <Grid item xs={12} md={7} display={'flex'} justifyContent={'center'}>
          <Grid
            container
            py={3}
            spacing={3}
            marginBottom={3}
            maxWidth={'650px'}
          >
            <Grid item xs={12} md={6}>
              <FormField
                id="firstName"
                placeholder={t('start:form.placeholderFirstName')}
                value={data.firstName}
                onChange={(e) => {
                  handleTextFieldChange(e, 'firstName');
                }}
              />
              {dataError.firstName !== '' && (
                <FieldError>{dataError.firstName}</FieldError>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <FormField
                id="lastName"
                placeholder={t('start:form.placeholderLastName')}
                value={data.lastName}
                onChange={(e) => {
                  handleTextFieldChange(e, 'lastName');
                }}
              />
              {dataError.lastName !== '' && (
                <FieldError>{dataError.lastName}</FieldError>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                id="email"
                placeholder={t('start:form.placeholderEmail')}
                value={data.email}
                onChange={(e) => {
                  handleTextFieldChange(e, 'email');
                }}
              />
              {dataError.email !== '' && (
                <FieldError>{dataError.email}</FieldError>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                id="phone"
                placeholder={t('start:form.placeholderPhone')}
                value={data.phoneNumber}
                onChange={(e) => {
                  handleTextFieldChange(e, 'phoneNumber');
                }}
              />
              {dataError.phoneNumber !== '' && (
                <FieldError>{dataError.phoneNumber}</FieldError>
              )}
            </Grid>

            <Grid item xs={12}>
              <FormField
                placeholder={t('start:form.placeholderMessage')}
                id="question"
                multiline
                value={data.question}
                onChange={(e) => {
                  handleTextFieldChange(e, 'question');
                }}
              />
              {dataError.question !== '' && (
                <FieldError>{dataError.question}</FieldError>
              )}
            </Grid>

            {(dataError.termsAcceptedOne === false ||
              dataError.termsAcceptedTwo === false) && (
              <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                <FieldError>{t('start:form.infoToAcceptFields')}</FieldError>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              display={'flex'}
              alignItems={'top'}
              alignContent={'top'}
            >
              <StyledCheckbox
                id="checkbox1"
                sx={{ height: '42px' }}
                checked={termsAcceptedOne}
                onChange={() => {
                  setTermsAcceptedOne(!termsAcceptedOne);
                  setDataError({ ...dataError, termsAcceptedOne: true });
                }}
              />
              <Typography fontSize={'12px'}>
                {t('start:form.acceptanceOne')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              display={'flex'}
              alignItems={'top'}
              alignContent={'top'}
            >
              <StyledCheckbox
                id="checkbox2"
                sx={{ height: '42px' }}
                checked={termsAcceptedTwo}
                onChange={() => {
                  setTermsAcceptedTwo(!termsAcceptedTwo);
                  setDataError({ ...dataError, termsAcceptedTwo: true });
                }}
              />
              <Typography fontSize={'12px'}>
                {t('start:form.acceptanceTwo')}
              </Typography>
            </Grid>
            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
              <Button
                onClick={onSubmit}
                id="submitButton"
                sx={{
                  width: '100%',
                  maxWidth: '300px',
                  height: '44px',
                  borderRadius: '1rem',
                  backgroundColor: colors.primary,
                  textTransform: 'none',
                }}
                variant="contained"
                color="primary"
              >
                <Typography style={{ fontSize: '1.125rem', color: 'white' }}>
                  {' '}
                  {t('start:form.send')}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <Box sx={{ ...modalStyle, width: 600, height: '100px' }}>
          {isLoading ? (
            <Box
              width={'100%'}
              height="100%"
              display={'flex'}
              justifyContent={'center'}
              alignContent={'center'}
              alignItems={'center'}
              marginBottom={'10px'}
            >
              <Typography>{t('start:form.sendingWithDots')}</Typography>
            </Box>
          ) : (
            <Box>
              <Box
                width={'100%'}
                display={'flex'}
                justifyContent={'center'}
                marginBottom={'10px'}
              >
                <Typography>{t('start:form.thankYouInfo')}</Typography>
              </Box>
              <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                <Button
                  variant="contained"
                  sx={{ height: '30px' }}
                  onClick={() => setShowModal(false)}
                >
                  {t('start:form.ok')}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default AskQuestionForm;
