import PriceTile from './PriceTile';
import { TileModel } from './TileFeaturesModel';
import { ReactComponent as Icon1 } from 'assets/french/1.svg';
import { ReactComponent as Icon2 } from 'assets/french/7.svg';
import { ReactComponent as Icon3 } from 'assets/french/4.svg';
import { ReactComponent as Icon4 } from 'assets/start/20.svg';
import { ReactComponent as Icon5 } from 'assets/start/35.svg';
import { ReactComponent as Icon6 } from 'assets/start/17.svg';
import { ReactComponent as Icon7 } from 'assets/start/29.svg';
import { ReactComponent as Icon9 } from 'assets/start/23.svg';
import { ReactComponent as Icon10 } from 'assets/french/10.svg';
import { ReactComponent as Icon11 } from 'assets/french/13.svg';
import { ReactComponent as Icon12 } from 'assets/french/31.svg';
import PriceListTemplate from './PriceListTemplate';
import { Box, useMediaQuery, useTheme } from '@mui/material';

const tiles: TileModel[] = [
  {
    title: 'Chat AI raisonné',
    features: [
      {
        title:
          'Synthèse des documents juridiques et proposition de solution de cas avec résumé',
        icon: <Icon1 />,
      },
      {
        title:
          'Justification des réponses par des sources juridiques et évaluation de leur pertinence',
        icon: <Icon2 />,
      },
      {
        title: 'Historique de chaque conversation avec leurs sources',
        icon: <Icon3 />,
      },
    ],
  },
  {
    title: "Moteur de recherche d'articles et de jurisprudence",
    features: [
      {
        title:
          'Recherche de jurisprudence par article, indépendamment ou en fonction du contexte de la conversation',
        icon: <Icon6 />,
      },
      {
        title:
          'Recherche rapide de toutes les lois françaises par sémantique de requête',
        icon: <Icon5 />,
      },
      {
        title:
          'Option de recherche de jurisprudence par référence ou par phrases (ex: "impôt sur le revenu")',
        icon: <Icon4 />,
      },
    ],
  },
  {
    title: 'Générateur de documents',
    features: [
      {
        title:
          'Création dynamique de documents juridiques en réponse aux demandes des utilisateurs',
        icon: <Icon7 />,
      },
      /*{
        title: 'Analyse des documents juridiques',
        icon: <Icon8 />,
      },*/
      {
        title: 'Génération de documents DOCX formatés',
        icon: <Icon9 />,
      },
    ],
  },
  {
    title: 'Navigateur de lois françaises',
    features: [
      {
        title: 'Navigation et consultation facile des lois',
        icon: <Icon11 />,
      },
      {
        title:
          'Recherche de jurisprudence correspondant à un article spécifique',
        icon: <Icon10 />,
      },
      {
        title:
          'Mise en évidence des passages les plus pertinents des documents juridiques',
        icon: <Icon12 />,
      },
    ],
  },
];

const PriceList = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div style={{ backgroundColor: '#005b2f', padding: '70px' }}>
      <Box
        width={'100%'}
        px={isMd ? 2 : 40}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        mb={6}
        mt={8}
      >
        <h1
          style={{
            color: '#38b6ff',
            fontSize: '48px',
            fontFamily: 'Merriweather',
          }}
        >
          LaLoire, c'est 4 programmes en 1:
        </h1>
      </Box>
      <Box
        width={'100%'}
        px={isMd ? 2 : 40}
        display={'flex'}
        justifyContent={'center'}
      >
        <PriceListTemplate>
          {tiles.map((tile, index) => (
            <PriceTile
              key={index}
              tileData={tile}
              colored={index === 1 || index === 2}
            />
          ))}
        </PriceListTemplate>
      </Box>
    </div>
  );
};

export default PriceList;
