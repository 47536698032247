import { TextField, TextFieldProps } from '@mui/material';
import { getCssVariable } from 'styles/getVariables';
import SendIcon from '@mui/icons-material/Send';
import InputAdornment from '@mui/material/InputAdornment';
import { ChangeEvent, useState } from 'react';

type Props = {
  textfieldProps: TextFieldProps;
  onAskQuestion: (value: string) => void;
  disabled: boolean;
};

const QuestionTextField = ({
  textfieldProps,
  disabled,
  onAskQuestion,
}: Props) => {
  const [currentQuestion, setCurrentQuestion] = useState<string>('');

  const handleSubmit = () => {
    if (currentQuestion.trim() && !disabled) {
      onAskQuestion(currentQuestion);
      setCurrentQuestion('');
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentQuestion(e.target.value);
  };

  return (
    <TextField
      inputRef={textfieldProps.inputRef}
      value={disabled ? '' : currentQuestion}
      onKeyDown={handleKeyPress}
      onChange={handleOnChange}
      sx={{
        background: 'var(--background-color-primary)',
        fontSize: '14px',
      }}
      fullWidth
      multiline
      disabled={disabled}
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SendIcon
              sx={{
                color: disabled
                  ? getCssVariable('--text-disabled')
                  : getCssVariable('--text-primary'),
              }}
              onClick={handleSubmit}
              style={{ cursor: disabled ? 'default' : 'pointer' }}
            />
          </InputAdornment>
        ),
      }}
      {...textfieldProps}
    >
      {textfieldProps.children}
    </TextField>
  );
};

export default QuestionTextField;
