export const commonCourts = [
  'Sąd Apelacyjny w Białymstoku',
  'Sąd Apelacyjny w Gdańsku',
  'Sąd Apelacyjny w Katowicach',
  'Sąd Apelacyjny w Krakowie',
  'Sąd Apelacyjny w Łodzi',
  'Sąd Apelacyjny w Lublinie',
  'Sąd Apelacyjny w Poznaniu',
  'Sąd Apelacyjny w Rzeszowie',
  'Sąd Apelacyjny w Warszawie',
  'Sąd Apelacyjny we Wrocławiu',
  'Sąd Apelacyjny w Szczecinie',
  'Sąd Okręgowy Warszawa-Praga w Warszawie',
  'Sąd Okręgowy w Białymstoku',
  'Sąd Okręgowy w Bielsku-Białej',
  'Sąd Okręgowy w Bydgoszczy',
  'Sąd Okręgowy w Częstochowie',
  'Sąd Okręgowy w Elblągu',
  'Sąd Okręgowy w Gdańsku',
  'Sąd Okręgowy w Gliwicach',
  'Sąd Okręgowy w Gorzowie Wielkopolskim',
  'Sąd Okręgowy w Jeleniej Górze',
  'Sąd Okręgowy w Kaliszu',
  'Sąd Okręgowy w Katowicach',
  'Sąd Okręgowy w Kielcach',
  'Sąd Okręgowy w Koninie',
  'Sąd Okręgowy w Koszalinie',
  'Sąd Okręgowy w Krakowie',
  'Sąd Okręgowy w Krośnie',
  'Sąd Okręgowy w Legnicy',
  'Sąd Okręgowy w Łodzi',
  'Sąd Okręgowy w Łomży',
  'Sąd Okręgowy w Lublinie',
  'Sąd Okręgowy w Nowym Sączu',
  'Sąd Okręgowy w Olsztynie',
  'Sąd Okręgowy w Opolu',
  'Sąd Okręgowy w Ostrołęce',
  'Sąd Okręgowy w Piotrkowie Trybunalskim',
  'Sąd Okręgowy w Płocku',
  'Sąd Okręgowy w Poznaniu',
  'Sąd Okręgowy w Przemyślu',
  'Sąd Okręgowy w Radomiu',
  'Sąd Okręgowy w Rybniku',
  'Sąd Okręgowy w Rzeszowie',
  'Sąd Okręgowy w Siedlcach',
  'Sąd Okręgowy w Sieradzu',
  'Sąd Okręgowy w Słupsku',
  'Sąd Okręgowy w Sosnowcu',
  'Sąd Okręgowy w Suwałkach',
  'Sąd Okręgowy w Świdnicy',
  'Sąd Okręgowy w Szczecinie',
  'Sąd Okręgowy w Tarnobrzegu',
  'Sąd Okręgowy w Tarnowie',
  'Sąd Okręgowy w Toruniu',
  'Sąd Okręgowy w Warszawie',
  'Sąd Okręgowy w Zamościu',
  'Sąd Okręgowy w Zielonej Górze',
  'Sąd Okręgowy we Włocławku',
  'Sąd Okręgowy we Wrocławiu',
];

export const administrativeCourts = [
  'Naczelny Sąd Administracyjny w Warszawie',
  'Wojewódzki Sąd Administracyjny w Białymstoku',
  'Wojewódzki Sąd Administracyjny w Bydgoszczy',
  'Wojewódzki Sąd Administracyjny w Gdańsku',
  'Wojewódzki Sąd Administracyjny w Gliwicach',
  'Wojewódzki Sąd Administracyjny w Gorzowie Wielkopolskim',
  'Wojewódzki Sąd Administracyjny w Kielcach',
  'Wojewódzki Sąd Administracyjny w Krakowie',
  'Wojewódzki Sąd Administracyjny w Lublinie',
  'Wojewódzki Sąd Administracyjny w Łodzi',
  'Wojewódzki Sąd Administracyjny w Olsztynie',
  'Wojewódzki Sąd Administracyjny w Opolu',
  'Wojewódzki Sąd Administracyjny w Poznaniu',
  'Wojewódzki Sąd Administracyjny w Radomiu',
  'Wojewódzki Sąd Administracyjny w Rzeszowie',
  'Wojewódzki Sąd Administracyjny w Szczecinie',
  'Wojewódzki Sąd Administracyjny w Warszawie',
  'Wojewódzki Sąd Administracyjny we Wrocławiu',
];

export const supremeCourts = [
  'Sąd Najwyższy',
  'Trybunał Konstytucyjny',
  'Krajowa Izba Odwoławcza',
];
