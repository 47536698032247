import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  TypographyProps,
  styled,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import colors from '../../../../../../configs/colorConfig';
import { useLocation } from 'react-router-dom';
import BotResponse from '../bot-response/BotResponse';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import styles from './styles.module.scss';
import { getCssVariable } from 'styles/getVariables';

const UserTypography = styled(Typography)<TypographyProps>({
  color: colors.chat.userTextColor,
  marginRight: '1px',
  fontSize: '14px',
  fontWeight: 'bold',
  display: 'inline-block',
});

const BotTypography = styled(Typography)<TypographyProps>({
  color: colors.chat.userTextColor,
  marginRight: '1px',
  fontSize: '14px',
  fontWeight: 'bold',
  display: 'inline-block',
});

type Props = {
  onQuestionClick?: (index: number) => void;
  listOfQuestions: string[];
  listOfResponses: string[];
  currentResponse: string;
  disableQuestionClick?: boolean;
  loadingResponseText?: string;
};
const Chat = ({
  onQuestionClick,
  listOfQuestions,
  listOfResponses,
  currentResponse,
  disableQuestionClick,
  loadingResponseText,
}: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const gridRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();

  const BOTTOM_SCROLL_MARGIN = 200;

  const [userScrolling, setUserScrolling] = useState(false);
  const [isNearBottom, setIsNearBottom] = useState(true);

  const debouncedHandleScroll = debounce(() => {
    const gridElement = gridRef.current;
    if (gridElement) {
      const { scrollTop, scrollHeight, clientHeight } = gridElement;
      const distanceToBottom = scrollHeight - scrollTop - clientHeight;
      const isNear = distanceToBottom <= BOTTOM_SCROLL_MARGIN;
      setIsNearBottom(isNear);
      setUserScrolling(false);
    }
  }, 100);

  useEffect(() => {
    const gridElement = gridRef.current;
    if (gridElement) {
      gridElement.addEventListener('scroll', debouncedHandleScroll);
      return () => {
        gridElement.removeEventListener('scroll', debouncedHandleScroll);
        debouncedHandleScroll.cancel();
      };
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const gridElement = gridRef.current;
    if (gridElement && !userScrolling && isNearBottom) {
      gridElement.scrollTop = gridElement.scrollHeight;
    }
  }, [currentResponse, userScrolling, isNearBottom]);

  useEffect(() => {
    const gridElement = gridRef.current;
    if (gridElement) {
      gridElement.scrollTop = gridElement.scrollHeight;
    }
  }, [listOfQuestions]);

  const handleManualScroll = () => {
    setUserScrolling(true);
    debouncedHandleScroll();
  };

  useEffect(() => {
    const gridElement = gridRef.current;
    if (gridElement) {
      gridElement.addEventListener('wheel', handleManualScroll);
      return () => {
        gridElement.removeEventListener('wheel', handleManualScroll);
      };
    }
    //eslint-disable-next-line
  }, []);

  const handleClickQuestion = async (index: number) => {
    if (disableQuestionClick || !onQuestionClick) return;
    onQuestionClick(index);
  };

  useEffect(() => {
    if (location.pathname === '/czat') {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Grid
      ref={gridRef}
      item
      xs
      pr={0}
      pt={3}
      style={{ flexGrow: 1, overflowY: 'auto' }}
    >
      {listOfQuestions.map((question, index) => (
        <Grid container key={index} px={2}>
          <Grid item xs={12} py={1}>
            <div
              className={`${styles.chatBubble} ${styles.questionBubble} ${onQuestionClick ? styles.questionBubbleHover : ''}`}
              onClick={() => handleClickQuestion(index)}
            >
              <Box display={'flex'}>
                <div className={styles.chatPersonTitle}>
                  <UserTypography>{t('dashboard:chat.you')}</UserTypography>
                </div>
              </Box>
              <p
                style={{
                  fontSize: '14px',
                  color: getCssVariable('--text-primary'),
                }}
              >
                {question}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} py={1}>
            <div className={`${styles.chatBubble} ${styles.responseBubble}`}>
              <div className={styles.chatPersonTitle}>
                <BotTypography>{'PrawMi'}</BotTypography>
              </div>
              <>
                {index === listOfResponses.length ? (
                  <Box>
                    {isLoading ? (
                      <CircularProgress
                        size={'22px'}
                        sx={{ margin: 'auto', marginTop: '10px' }}
                      />
                    ) : (
                      <BotResponse
                        loadingResponseText={loadingResponseText}
                        data-testid="response-display"
                        responseText={currentResponse}
                      />
                    )}
                  </Box>
                ) : (
                  <>
                    <BotResponse responseText={listOfResponses[index]} />
                  </>
                )}
              </>
            </div>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default Chat;
