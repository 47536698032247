import { useNavigate } from 'react-router-dom';
import colors from '../../../../configs/colorConfig';
import { BlogArticleModel } from '../../../../models/BlogArticle';

type Props = {
  article: BlogArticleModel;
};

function formatDate(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };

  return date.toLocaleDateString('pl-PL', options);
}

const BlogItem = ({ article }: Props) => {
  const navigate = useNavigate();

  const onMouseEnter = (e: any) => {
    if (e) {
      e.currentTarget.firstChild.style.transform = 'scale(1.1)';
    }
  };

  const onMouseLeave = (e: any) => {
    if (e) {
      e.currentTarget.firstChild.style.transform = 'scale(1)';
    }
  };

  return (
    <div style={{ margin: '30px', width: '370px' }}>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={() => {
          navigate('/blog/' + article.url);
        }}
        style={{
          cursor: 'pointer',
        }}
      >
        <img
          src={article.image}
          alt="blog"
          style={{
            width: '100%',
            height: '280px',
            borderRadius: '15px',
            border: '1px solid #e0e0e0',
            objectFit: 'cover',
            objectPosition: 'left center',
            transition: 'transform 0.3s ease-in-out',
          }}
        />
        <div style={{ marginTop: '20px' }}>
          <p style={{ fontSize: '18px', textAlign: 'center' }}>
            <strong> {article.title}</strong>
          </p>
          <p
            style={{
              fontSize: '16px',
              fontWeight: '300px',
              textAlign: 'center',
            }}
          >
            {formatDate(article.date)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogItem;
