import { Button } from '@mui/material';
import MuiLink from '@mui/material/Link';
import React from 'react';
import { useNavigate } from 'react-router';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <div style={{ background: '#d9d9d9', height: '100px' }}></div>
    </>
  );
};
export default Footer;
