import RegistrationPanel from '../../components/organisms/registration-panel/RegistrationPanel';
import { Helmet } from 'react-helmet';
import Notificator from '../../../common/layout/notificator/Notificator';
import LoginRegistrationTemplate from '../../components/templates/login-registration-template/LoginRegistrationTemplate';

export const RegistrationPage = () => {
  return (
    <>
      <Helmet>
        <title>
          PrawMi to nowa inteligentna wyszukiwarka prawnicza wspierana przez AI.
        </title>
      </Helmet>
      <LoginRegistrationTemplate>
        <RegistrationPanel />
      </LoginRegistrationTemplate>
      <Notificator />
    </>
  );
};

export default RegistrationPage;
