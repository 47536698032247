import { Box, Grid, Typography } from '@mui/material';
import image1 from '../../../../../../assets/images/1-2.webp';
import image2 from '../../../../../../assets/images/3-1-1.webp';
import image3 from '../../../../../../assets/images/1X-1.webp';
import image4 from '../../../../../../assets/images/5-1-1.webp';
import image5 from '../../../../../../assets/images/4X.webp';

const SingleLineSchema = () => {
  const renderImageBox = (
    image: string,
    borderType: 'all' | 'top' | 'bottom',
    style: React.CSSProperties = {}
  ) => (
    <Box
      style={{
        background: 'white !important',
        border: '1px solid lightgray',
        borderRadius: '10px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
        ...style,
      }}
    >
      <Box
        component="img"
        width="100%"
        src={image}
        style={{
          borderRadius: borderType === 'all' ? '9px' : 0,
          borderTopLeftRadius:
            borderType === 'top' || borderType === 'all' ? '9px' : 0,
          borderTopRightRadius:
            borderType === 'top' || borderType === 'all' ? '9px' : 0,
          borderBottomLeftRadius:
            borderType === 'bottom' || borderType === 'all' ? '9px' : 0,
          borderBottomRightRadius:
            borderType === 'bottom' || borderType === 'all' ? '9px' : 0,
          overflow: 'hidden',
        }}
      />
    </Box>
  );

  const renderTypography = (
    text: string,
    fontSize = '32px',
    style: React.CSSProperties = {}
  ) => (
    <Typography
      color="White"
      fontSize={fontSize}
      fontWeight="bold"
      textAlign="center"
      sx={{ marginBottom: '20px', ...style }}
    >
      {text}
    </Typography>
  );

  return (
    <Grid container pt={'250px'} px={'30px'} pb="10px" spacing={1}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>{renderTypography('Opisz dokładnie casus', '3rem')}</Grid>
        <Grid item>
          {renderImageBox(image1, 'all', {
            maxWidth: '550px',
            marginBottom: '10px',
          })}
        </Grid>
      </Grid>
      <Grid item xs={12} display={'flex'} justifyContent={'center'}>
        {renderTypography(
          'PrawMi zwróci Ci relewantne artykuły oraz fragmenty orzeczeń',
          '32px',
          {
            whiteSpace: 'pre-line',
            marginBottom: '40px',
            marginTop: '40px',
          }
        )}
      </Grid>
      <Grid item xs={12} display={'flex'} justifyContent={'center'}>
        {renderImageBox(image2, 'all', {
          maxWidth: '700px',
        })}
      </Grid>
      <Grid item xs={12} display={'flex'} justifyContent={'center'}>
        {renderTypography(
          'Otrzymasz syntezę źródeł i podpowiedź dotyczącą badania prawnego',
          '32px',
          { marginBottom: '40px', marginTop: '40px' }
        )}
      </Grid>
      <Grid item xs={12} display={'flex'} justifyContent={'center'}>
        <Box>
          {renderImageBox(image3, 'top', {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            maxWidth: '500px',
            marginBottom: '40px',
          })}
          {renderImageBox(image4, 'bottom', {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            maxWidth: '500px',
          })}
        </Box>
      </Grid>

      <Grid item xs={12} display={'flex'} justifyContent={'center'}>
        {renderTypography(
          'Możesz też dla dowolnego artykułu\nznaleźć orzeczenia pasujące do pytania!',
          '32px',
          {
            whiteSpace: 'pre-line',
            marginBottom: '40px',
            marginTop: '40px',
          }
        )}
      </Grid>

      <Grid item xs={12} display={'flex'} justifyContent={'center'}>
        <Box mb={0} />
        <Box
          style={{
            background: 'white',
            border: '1px solid lightgray',
            borderRadius: '10px',
            padding: '0px',
            maxWidth: '750px',
            marginBottom: '40px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
          }}
        >
          <Box
            component={'img'}
            width={'100%'}
            src={image5}
            style={{
              borderRadius: '10px',
              overflow: 'hidden',
            }}
          />
        </Box>
        <Box mb={10} />
      </Grid>
      {/* <ServiceTileSection /> */}
      <Box mb={10} />
    </Grid>
  );
};

export default SingleLineSchema;
