export const createElementModelForTree = (
  name: string,
  data: any,
  match: boolean,
  sectionName:
    | 'books'
    | 'titles'
    | 'sections'
    | 'chapters'
    | 'subchapters'
    | 'articles'
    | 'points'
    | 'paragraphs'
    | 'letters'
) => {
  const sectionNames = [
    'books',
    'titles',
    'sections',
    'chapters',
    'subchapters',
    'articles',
    'paragraphs',
    'points',
    'letters',
  ];

  let childIndex = sectionNames.indexOf(sectionName);
  let childName = '';
  if (childIndex < sectionNames.length - 1) {
    childName = sectionNames[childIndex + 1];
  }

  let text = '';
  if (sectionName === 'books') {
    if (
      data[name][''] &&
      data[name][''][''] &&
      data[name][''][''][''] &&
      data[name][''][''][''][''] &&
      data[name][''][''][''][''][''] &&
      data[name][''][''][''][''][''][''] &&
      data[name]['']['']['']['']['']['']['']
    ) {
      text = data[name][''][''][''][''][''][''][''][''];
    }
  } else if (sectionName === 'titles') {
    if (
      data[name][''] &&
      data[name][''][''] &&
      data[name][''][''][''] &&
      data[name][''][''][''][''] &&
      data[name][''][''][''][''][''] &&
      data[name]['']['']['']['']['']['']
    ) {
      text = data[name][''][''][''][''][''][''][''];
    }
  } else if (sectionName === 'sections') {
    if (
      data[name][''] &&
      data[name][''][''] &&
      data[name][''][''][''] &&
      data[name][''][''][''][''] &&
      data[name]['']['']['']['']['']
    ) {
      text = data[name][''][''][''][''][''][''];
    }
  } else if (sectionName === 'chapters') {
    if (
      data[name][''] &&
      data[name][''][''] &&
      data[name][''][''][''] &&
      data[name]['']['']['']['']
    ) {
      text = data[name][''][''][''][''][''];
    }
  } else if (sectionName === 'subchapters') {
    if (data[name][''] && data[name][''][''] && data[name]['']['']['']) {
      text = data[name][''][''][''][''];
    }
  } else if (sectionName === 'articles') {
    if (data[name][''] && data[name]['']['']) {
      text = data[name][''][''][''];
    }
  } else if (sectionName === 'paragraphs') {
    if (data[name]['']) {
      text = data[name][''][''];
    }
  } else if (sectionName === 'points') {
    if (data[name]) {
      text = data[name][''];
    }
  } else if (sectionName === 'letters') {
    if (data[name]) {
      text = data[name];
    }
  } else if (sectionName === 'articles') {
    if (data[name][''] && data[name]['']['']) {
      text = data[name][''][''][''];
    }
  } else if (sectionName === 'paragraphs') {
    if (data[name]['']) {
      text = data[name][''][''];
    }
  } else if (sectionName === 'points') {
    if (data[name]) {
      text = data[name][''];
    }
  } else if (sectionName === 'letters') {
    if (data[name]) {
      text = data[name];
    }
  }
  let displayText = '';

  if (
    ['books', 'titles', 'sections', 'chapters', 'subchapters'].includes(
      sectionName
    )
  ) {
    displayText =
      "<p style='font-weight:700;font-size:16px;'>" +
      (name + ' ' + text) +
      '</p>';
  } else if (sectionName !== 'articles') {
    displayText = '<p>' + (name + ' ' + text) + '</p>';
  } else {
    displayText = text === '' ? '' : '<p>' + text + '</p>';
  }

  let output;
  if (childName !== '') {
    output = {
      name: name,
      text: displayText,
      highlighted: match,
      [childName]: [],
    };
  } else {
    output = {
      name: name,
      text: displayText,
      highlighted: match,
    };
  }

  return output;
};
