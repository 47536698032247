import { Box, Link } from '@mui/material';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Seo = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box px={isMd ? 4 : 21} style={{ background: '#d9d9d9' }}>
      <div style={{ marginTop: '40px', marginBottom: '30px' }}>
        <h4
          className="font-bold text-4xl"
          style={{
            fontSize: '1.875rem',
            color: '#005b2f',
            fontFamily: 'Merriweather',
          }}
        >
          Qui sommes-nous ?
        </h4>
        <div className="text-md font-medium text-gray-600">
          <div>
            <div style={{ display: isExpanded ? 'block' : 'none' }}>
              <p
                style={{
                  fontSize: '16px',
                  fontFamily: 'Merriweather',
                  color: '#005b2f',
                }}
              >
                <strong>
                  L'outil d'automatisation de la recherche juridique
                </strong>{' '}
                efficace PrawMi est une{' '}
                <strong>application innovante pour les juristes</strong> qui
                propose des solutions avancées dans le domaine de la{' '}
                <strong>legal tech</strong>. Grâce à notre plateforme, vous
                pouvez analyser rapidement les{' '}
                <strong>lois, actes juridiques, documents judiciaires</strong>{' '}
                et <strong>décisions</strong>.
              </p>
              <p
                style={{
                  fontSize: '16px',
                  marginTop: '10px',
                  fontFamily: 'Merriweather',
                  color: '#005b2f',
                }}
              >
                Notre application n'est pas seulement une{' '}
                <strong>base de données juridiques en ligne</strong>, mais aussi
                un <strong>outil juridique électronique</strong> qui améliorera
                considérablement votre{' '}
                <strong>efficacité de travail juridique</strong>. Que vous soyez{' '}
                <strong>avocat(e)</strong>,{' '}
                <strong>conseiller juridique</strong> ou employé(e) d'un{' '}
                <strong>cabinet juridique</strong>, PrawMi vous apportera l'aide
                nécessaire dans votre travail quotidien.
              </p>
              <p
                style={{
                  fontSize: '16px',
                  marginTop: '10px',
                  fontFamily: 'Merriweather',
                  color: '#005b2f',
                }}
              >
                Grâce à l'utilisation de{' '}
                <strong>l'intelligence artificielle (IA)</strong>, notre{' '}
                <strong>assistant de recherche juridique</strong> facilite la
                réalisation de <strong>recherches juridiques</strong>.
              </p>
            </div>
            <p
              style={{
                fontSize: '16px',
                fontFamily: 'Merriweather',
                color: '#005b2f',
                display: isExpanded ? 'none' : 'block',
              }}
            >
              <strong>
                L'outil d'automatisation de la recherche juridique
              </strong>{' '}
              efficace PrawMi est une{' '}
              <strong>application innovante pour les juristes</strong> qui
              propose des solutions avancées dans le domaine de la{' '}
              <strong>legal tech</strong>.
            </p>

            <Link
              onClick={() => setIsExpanded(!isExpanded)}
              style={{ cursor: 'pointer', fontSize: '16px' }}
            >
              {isExpanded ? 'Réduire' : 'Plus'}
            </Link>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Seo;
