import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import styles from './styles.module.scss';
import { DocumentModel } from 'module-private/models/DocumentModel';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { getCssVariable } from 'styles/getVariables';
import { parseGPT4oMarkdown } from '../chat-feed/components/bot-response/BotResponse';

type Props = {
  documents: DocumentModel[];
  loading: boolean;
  onDeleteClick: (id: string) => void;
};

const ListOfDocuments = ({ documents, loading, onDeleteClick }: Props) => {
  const navigate = useNavigate();

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleDocumentClick = (id: string) => {
    navigate(`/dokument/${id}`);
  };

  const getText = (doc: DocumentModel) => {
    const text =
      doc.content && doc.content.length > 100
        ? doc.content.substring(0, 100)
        : (doc.content ?? '');
    let html = parseGPT4oMarkdown(text ?? '');
    html = html.replaceAll('<br>', '');
    html = html.replaceAll('<br/>', '');

    html = html.replaceAll(/[\n\r]/g, '');
    return html + ' ...';
  };

  return (
    <div
      style={{
        height: '100%',
        maxHeight: '100%',
        position: 'relative',
      }}
    >
      {documents.length === 0 ? (
        <div>
          Brak wygenerowanych dokumentów. Proszę, wpisz swoje zapytanie o
          dokument.
        </div>
      ) : (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflowY: 'auto',
            paddingLeft: '20px',
            paddingRight: '20px',
          }}
        >
          {documents.map((doc) => (
            <div key={doc.id} className={styles.documentItem}>
              <div style={{ display: 'flex' }}>
                <div
                  className={styles.documentItemContent}
                  onClick={() => handleDocumentClick(doc.id)}
                >
                  <p>{format(doc.lastModified, 'dd.MM.yy HH:mm')}</p>
                  <div dangerouslySetInnerHTML={{ __html: getText(doc) }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton onClick={() => onDeleteClick(doc.id)}>
                    <DeleteIcon
                      sx={{ color: getCssVariable('--text-primary') }}
                    />
                  </IconButton>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ListOfDocuments;
