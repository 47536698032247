import PriceTile from './PriceTile';
import { TileModel } from './TileFeaturesModel';
import { ReactComponent as Icon1 } from 'assets/start/1.svg';
import { ReactComponent as Icon2 } from 'assets/start/7.svg';
import { ReactComponent as Icon3 } from 'assets/start/4.svg';
import { ReactComponent as Icon4 } from 'assets/start/20.svg';
import { ReactComponent as Icon5 } from 'assets/start/35.svg';
import { ReactComponent as Icon6 } from 'assets/start/17.svg';
import { ReactComponent as Icon7 } from 'assets/start/29.svg';
import { ReactComponent as Icon8 } from 'assets/start/26.svg';
import { ReactComponent as Icon9 } from 'assets/start/23.svg';
import { ReactComponent as Icon10 } from 'assets/start/10.svg';
import { ReactComponent as Icon11 } from 'assets/start/13.svg';
import { ReactComponent as Icon12 } from 'assets/start/31.svg';
import PriceListTemplate from './PriceListTemplate';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import colors from '../../../../../configs/colorConfig';


const tiles: TileModel[] = [
  {
    title: 'Rozumujący czat AI',
    features: [
      {
        title:
          'Synteza dokumentów prawnych i propozycja rozwiązania casusu wraz z podsumowaniem',
        icon: <Icon1 />,
      },
      {
        title:
          'Uzasadnienie odpowiedzi źródłami prawnymi i ocena ich przydatności',
        icon: <Icon2 />,
      },
      {
        title: 'Historia każdej konwersacji wraz z ich źródłami',
        icon: <Icon3 />,
      },
    ],
  },
  {
    title: 'Wyszukiwarka artykułów i orzeczeń',
    features: [
      {
        title:
          'Wyszukiwanie orzeczeń do artykułu niezależnie lub zależnie od kontekstu konwersacji',
        icon: <Icon6 />,
      },
      {
        title:
          'Szybkie znajdywanie wszystkich polskich ustaw po semantyce zapytania',
        icon: <Icon5 />,
      },
      {
        title:
          'Opcja wyszukiwania orzeczeń po sygnaturze lub po frazach (np. "podatek dochodowy")',
        icon: <Icon4 />,
      },
    ],
  },
  {
    title: 'Generator dokumentów',
    features: [
      {
        title: 'Dynamiczne kształtowanie dokumentów prawnych w odpowiedzi na zapytania użytkownika',
        icon: <Icon7 />,
      },
      /*{
        title: 'Analizowanie dokumentów prawnych',
        icon: <Icon8 />,
      },*/
      {
        title: 'Zwracanie sformatowanych dokumentów DOCX',
        icon: <Icon9 />,
      },
    ],
  },
  {
    title: 'Przeglądarka polskich ustaw',
    features: [
      {
        title: 'Wygodne przeglądanie i nawigacja po ustawach',
        icon: <Icon11 />,
      },
      {
        title: 'Wyszukiwarka orzeczeń pasujących do danego artykułu',
        icon: <Icon10 />,
      },
      {
        title: 'Podświetlanie najistotniejszych fragmentów dokumentów prawnych',
        icon: <Icon12 />,
      },
    ],
  },
];

const PriceList = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Box
        width={'100%'} 
        px={isMd ? 2 : 40}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        mb={6}
        mt={8}
      >
        <h1
          style={{ color: colors.primary }}
          className="mb-5 md:text-5xl text-3xl font-bold text-center"
        >
          PrawMi to 4 programy w 1:
        </h1>
      </Box>
      <Box
        width={'100%'}
        px={isMd ? 2 : 40}
        display={'flex'}
        justifyContent={'center'}
      >
        <PriceListTemplate>
          {tiles.map((tile, index) => (
            <PriceTile
              key={index}
              tileData={tile}
              colored={index === 1 || index === 2}
            />
          ))}
        </PriceListTemplate>
      </Box>
    </>
  );
};

export default PriceList;
