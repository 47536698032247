import { Button, CircularProgress } from '@mui/material';
import axios from 'api/axios';
import { auth } from 'configs/firebaseConfig';
import { DocumentModel } from 'module-private/models/DocumentModel';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getCssVariable } from 'styles/getVariables';
import { parseGPT4oMarkdown } from '../chat-feed/components/bot-response/BotResponse';
import LoadingDocumentPanel, {
  StatusEnum,
} from './components/LoadingDocumentPanel';

type Props = {
  documentModel: DocumentModel | null;
  content: string;
  loading: boolean;
  status: StatusEnum | null;
};

const DocumentDisplay = ({
  documentModel,
  content,
  loading,
  status,
}: Props) => {
  const { id } = useParams();
  const [response, setResponse] = useState<string>('');
  const contentRef = useRef<HTMLDivElement>(null);

  const downloadFile = async (doc: DocumentModel | null) => {
    if (!id) return;
    const token = await auth.currentUser?.getIdToken();
    try {
      const response = await axios.get(`documents/document-download/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: '*',
          'content-type': 'application/json',
        },
        responseType: 'blob',
      });

      const fallbackFilename = `PrawMi-${new Date()
        .toLocaleString('pl-PL', {
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        })
        .replace(/[\/,.\s:]/g, '-')}.docx`;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = response.data.filename || fallbackFilename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  useEffect(() => {
    let html = parseGPT4oMarkdown(
      content ?? documentModel?.content ?? '',
      true
    );
    html = html.replaceAll('<br>', '');
    setResponse(html);

    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {status === null ? (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <CircularProgress size={30} />
                <p>Generowanie dokumentu...</p>
              </div>
            </div>
          ) : (
            <div>
              <LoadingDocumentPanel status={status} />
            </div>
          )}
        </div>
      ) : (
        <>
          <div style={{ flex: 1, position: 'relative' }}>
            <div
              ref={contentRef}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflowY: 'auto',
                paddingLeft: '50px',
                paddingRight: '50px',
              }}
            >
              {/* <pre style={{ whiteSpace: 'pre-wrap', fontFamily: 'inherit' }}>
                {content ?? documentModel?.content}
              </pre> */}

              <div
                style={{
                  fontSize: '14px',
                  color: getCssVariable('--text-primary'),
                }}
                dangerouslySetInnerHTML={{ __html: response }}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              padding: '20px',
            }}
          >
            <Button
              variant="contained"
              onClick={() => downloadFile(documentModel)}
              sx={{ minWidth: '200px' }}
            >
              Pobierz
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default DocumentDisplay;
