import { Box, Button, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

type Props = {
  text: string;
};
const SynthesisAccordion = ({ text }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLDivElement | null>(null);
  const hiddenRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (textRef.current && hiddenRef.current) {
      const textHeight = textRef.current.clientHeight;
      const hiddenTextHeight = hiddenRef.current.clientHeight;
      if (textHeight < hiddenTextHeight) {
        setIsTruncated(true);
      } else {
        setIsTruncated(false);
      }
    }
  }, [text]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Box>
      <Typography
        variant="body1"
        ref={textRef}
        sx={{
          fontStyle: 'italic',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp: expanded ? 'none' : 2,
          lineClamp: expanded ? 'none' : 2,
          maxHeight: expanded ? 'none' : '3.6em',
        }}
      >
        {text}
      </Typography>

      <Typography
        variant="body1"
        ref={hiddenRef}
        sx={{
          visibility: 'hidden',
          position: 'absolute',
          top: 0,
          left: 0,
          height: 'auto',
          overflow: 'visible',
          whiteSpace: 'normal',
        }}
      >
        {text}
      </Typography>
      {isTruncated && (
        <Button onClick={toggleExpanded}>
          {expanded ? 'Mniej' : 'Więcej'}
        </Button>
      )}
    </Box>
  );
};

export default SynthesisAccordion;
